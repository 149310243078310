import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UmService } from '../um-service/um.service';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

@Injectable({
  providedIn: 'root'
})
export class XgsGuardGuard {
  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly umService: UmService,
    private readonly datadogService: DatadogService
  ) {}

  /**
   * Checks if user is authenticated
   * before allowing access to route
   * @param route route user is attempting to access
   * @returns true for access or Url Tree to authorize route
   */
  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      take(1),
      map(results => {
        try {
          const fullPath = this.getFullPath(route);
          const routeStorage = {
            path: fullPath || route.routeConfig.path,
            queryParams: route.queryParams
          };
          sessionStorage.setItem('route', JSON.stringify(routeStorage));
          const config = results.allConfigsAuthenticated.find(oidcConfig => {
            return oidcConfig.configId === this.umService.userIdp;
          });
          if (!config?.isAuthenticated) {
            this.oidcSecurityService.authorize(this.umService.userIdp || 'xgs');
          }

          return true;
        } catch (error) {
          this.datadogService.errorTracking(error, {
            message: `Error occurred authenticating for route ${route.routeConfig.path}`
          });
        }
      })
    );
  }

  /**
   * Checks if user is authenitcated before
   * allowing loading of lazy loaded modules
   * @param route
   * @param segments
   * @returns true or Url Tree for authorize route
   */
  canMatch(
    route: ActivatedRouteSnapshot,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      take(1),
      map(results => {
        const fullPath = segments
          .map(segment => {
            return segment.path;
          })
          .join('/');
        const routeStorage = {
          path: fullPath || route?.routeConfig?.path,
          queryParams: route?.queryParams
        };
        sessionStorage.setItem('route', JSON.stringify(routeStorage));
        const config = results?.allConfigsAuthenticated?.find(oidcConfig => {
          return oidcConfig.configId === this.umService.userIdp;
        });
        if (
          !config?.isAuthenticated &&
          results?.allConfigsAuthenticated?.length > 0
        ) {
          return false;
        }

        return true;
      })
    );
  }

  /**
   * Gets full path to route including parent route
   * @param route route user is attempting to access
   * @returns extracted path
   */
  private getFullPath(route: ActivatedRouteSnapshot): string {
    const pathSegments: string[] = [];

    // Build the path recursively by traversing the route's parent chain
    let currentRoute: ActivatedRouteSnapshot | null = route;
    while (currentRoute) {
      if (currentRoute?.url?.length > 0) {
        pathSegments.unshift(
          currentRoute.url
            .map(segment => {
              return segment.path;
            })
            .join('/')
        );
      }
      currentRoute = currentRoute.parent;
    }

    return pathSegments.join('/');
  }
}

// Declare type for canDeactivate
type CanDeactivateType =
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree;

export interface CanComponentDeactivate {
  canDeactivate: () => CanDeactivateType;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard {
  constructor(private readonly datadogService: DatadogService) {}
  /**
   * Guard used to remove preferences from localStorage
   * when leaving a module currently removes all but can
   * be customized with the data parameter from app routing
   * @param component component
   * @param currentRoute route user is leaving
   * @returns true
   */
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    try {
      const preference = JSON.parse(
        localStorage.getItem(currentRoute.routeConfig.path) || '{}'
      );
      if (preference.pageIndex !== undefined) {
        delete preference.pageIndex;
        localStorage.setItem(
          currentRoute.routeConfig.path,
          JSON.stringify(preference)
        );
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: `Error updating preferences for ${currentRoute.routeConfig.path}`
      });
    }

    return true;
  }
}
