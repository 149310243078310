import { Injectable } from '@angular/core';
import { UDLUserData } from '@common/models/user-management.model';
import { environment } from '@env/environment';
import { datadogRum } from '@datadog/browser-rum';
import { CustomTranslatorService } from 'app/shared/services/custom-translator/custom-translator.service';
import { AmplifyService } from 'app/aws/amplify/amplify.service';
import { ApiResponse } from '@common/models/api.model';
import { CookieService } from 'ngx-cookie-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

@Injectable({
  providedIn: 'root'
})
export class UmService {
  maxTimeDifference = 90000;
  userIdp: string;
  userData: UDLUserData;
  authResults = {} as OidcAuthResults;

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly customTranslatorService: CustomTranslatorService,
    private readonly amplifyService: AmplifyService,
    private readonly datadogService: DatadogService,
    private readonly cookieService: CookieService
  ) {
    this.userIdp = this.cookieService.get('referrer');
  }

  setUserData(userData: UDLUserData) {
    this.userData = userData;
  }

  getUserData(): UDLUserData {
    return this.userData;
  }

  /**
   * Sets the authentication results for the specified user IdP.
   */
  async setAuthResults(): Promise<void> {
    this.authResults = await lastValueFrom(
      this.oidcSecurityService.getAuthenticationResult(this.userIdp)
    );
  }

  /**
   * Sets the user's Identity Provider (IdP).
   *
   * Updates the `userIdp` property, sets the IdP in the Amplify service, and stores the IdP in a 'referrer' cookie.
   *
   * @param {string} idp - The IdP to set.
   */
  setUserIdp(idp: string): void {
    this.userIdp = idp;
    this.amplifyService.setAmplifyIdp(idp);
    this.cookieService.set(
      'referrer',
      idp,
      null,
      '/',
      this.domainHelper(),
      true,
      'None'
    );
  }

  /**
   * Gets user's roles/permissions from XGS
   * @param userData UDL user object
   * @param keycloakUserData User data collected from keycloak
   */
  async setUser(userData: UDLUserData): Promise<void> {
    await this.setAuthResults();
    if (environment.dataDogAppID) {
      if (userData.xgsRoles) {
        datadogRum.setUserProperty('roles', userData.xgsRoles);
      }
    }

    await this.customTranslatorService.getLocalizationFile(
      userData.user_metadata.locale
    );

    this.setUserData(userData);

    this.cookieService.set(
      `${environment.environmentName}_udl_locale`,
      userData.user_metadata?.locale || 'en_US',
      null,
      '/',
      this.domainHelper(),
      true,
      'None'
    );
  }

  /**
   * Checks if the client's time is synchronized with the server's time.
   *
   * @returns {Promise<boolean>} resolves to true if the time difference is within the allowed limit, false otherwise.
   */
  async checkTime(): Promise<boolean> {
    try {
      const apiResponse = await this.amplifyService.callAPI<ApiResponse>(
        'GET',
        'API_UDL_UM_AUDIT_TRAIL',
        '/synch-clock',
        null,
        false
      );
      const serverTime = new Date(apiResponse.data['serverTime']);
      const clientTime = new Date();
      const difference = Math.abs(clientTime.getTime() - serverTime.getTime());
      if (difference > this.maxTimeDifference) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while validating time synchronization...'
      });

      return true;
    }
  }

  /**
   * Helper function to extract
   * domain and if developing locally
   * @returns domain name
   */
  domainHelper(): string {
    const url = new URL(window.location.origin);
    const loc = url.href;
    const domain =
      !environment.production && loc.startsWith('http://localhost:4200/')
        ? 'localhost'
        : environment.domain;

    return domain;
  }
}

export interface OidcAuthResults {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
  refresh_expires_in: number;
  scope: string;
  session_state: string;
  state: string;
  token_type: string;
}
