<div *ngIf="data?.type === 'driver'">
    <div mat-dialog-title class="dialog-title">
      <span i18n="@@driver-download-dialog_driver-download-dialog_header" [textContent]="'driver-download-dialog_driver-download-dialog_header' | translate">Software Drivers</span>
      <button mat-icon-button class="dialog-close" (click)="closeDialog()">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
    </div>
    <mat-dialog-content class="dialog-info">
        <h4 i18n="@@driver-download-dialog_driver-download-dialog_latest" [textContent]="'driver-download-dialog_driver-download-dialog_latest' | translate">
          Supported Version -
        </h4>
        <p i18n="@@driver-download-dialog_driver-download-dialog_version" [textContent]="'driver-download-dialog_driver-download-dialog_version' | translate">
          Amazon Redshift ODBC Driver v1.4.49.1000 for Windows
        </p>
        <xg-button
            class="driver-download-button"
            buttonType="raised"
            buttonColor="primary"
            (click)="getDriver('AmazonRedshiftODBC64-1.4.49.1000')"
            [text]="'driver-download-dialog_driver-download-dialog_download' | translate">
        </xg-button>
    </mat-dialog-content>
</div>

<div *ngIf="data?.type === 'about'">
      <div mat-dialog-title class="dialog-title">
        {{ data?.title }}
        <button mat-icon-button class="dialog-close" (click)="closeDialog()">
          <mat-icon fontIcon="close"></mat-icon>
        </button>
      </div>
      <mat-dialog-content class="dialog-info">
        <div *ngFor="let info of data.info">
          {{ info }}
        </div>
      </mat-dialog-content>
</div>