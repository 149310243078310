import { ErrorHandler, Injectable } from '@angular/core';
import { Alert, AlertsService } from '@gravity-angular/base';
import { ColorType } from '@gravity-angular/models';
import { DatadogService } from '../services/datadog-services/datadog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  alert: Alert;

  constructor(
    private readonly alertsService: AlertsService,
    private readonly datadogService: DatadogService
  ) {}

  handleError(error: any): void {
    this.alert = {
      title: 'Error',
      message:
        'An unknown error has occurred. If the problem persists, please contact your admin.',
      type: ColorType.danger,
      dismissable: true
    };
    if (!error?.message.includes('Network error')) {
      this.alertsService.addAlert(this.alert);
    }
    this.datadogService.errorTracking(error, {
      message: 'Uncaught error'
    });
  }
}
