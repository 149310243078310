<div [formGroup]="form" style="margin: 3% 2% 1% 2%;">
    <div class="col-lg-6">
        <mat-form-field appearance="outline" floatLabel="always" [style.width.%]=150>
            <mat-label>System</mat-label>
            <mat-select placeholder="Select System" (selectionChange)="systemSelected($event['value'])" formControlName="system" required>
                <mat-option *ngFor="let system of availableSystems" [value]="system.value">{{system.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-12">
        <mat-form-field appearance="outline" floatLabel="always" class="description">
            <mat-label>Description {{this.currentSystem === 'other_system' ? '' : '(optional)'}}</mat-label>
            <textarea formControlName="description" type="text" matInput maxlength="250" placeholder="Description (max 250 characters)" [required]="this.currentSystem === 'other_system'"></textarea>
        </mat-form-field>
        <div *ngIf="form.controls.description.errors?.pattern" class="errorMessage">
            Please enter a valid description
        </div>
    </div>
    <div mat-dialog-actions>
        <div style="margin-right: 3%;">
            <button class="add-user-button" mat-button (click)="addLogin()" [disabled]="!this.form.valid">Add</button>
        </div>
        <div>
            <button class="cancel-user-button" mat-button mat-dialog-close="{{undefined}}">Cancel</button>
        </div>
    </div>
</div>