<xg-header
  [appName]="appName"
  [showSearch]="showSearch"
  (toggleSideNav)="toggleSideNav($event)"
>
  <ng-container brandLogo>
    <img src="assets/Icon_UDL.png" alt="appName" class="brand-icon" />
  </ng-container>
  <span headerLeftOptionalContent class="header-item">
    <app-commodity-switcher
      [ngClass]="utilityDisabled ? 'utility-disable' : 'utility-dropdown'"
      [selectedUtility]="selectedCustomer"
      [commodityOptions]="commodityOptions"
      [utilityOptions]="utilityOptions"
      (utilityChanged)="updateUtility($event)"
    ></app-commodity-switcher>
  </span>

  <ng-container headerRightOptionalContent>
    <a mat-menu-item routerLink="/landing" class="home-anchor">
      <mat-icon style="color: rgba(0, 0, 0, 0.54)">home</mat-icon>
    </a>
  </ng-container>

  <ng-container initial>
    <span style="font-family: Noto Sans, sans-serif;">
      {{ userData?.givenName?.charAt(0) }}
    </span>
  </ng-container>
  <ng-container userMenu>
    <div disabled="disabled" mat-menu-item class="user-menu-item">
      <mat-icon class="user-menu-icon">store</mat-icon>
      <span class="user-menu-text">
        {{ selectedCustomer?.customer?.customerId }}
      </span>
    </div>
    <div disabled="disabled" mat-menu-item class="user-menu-item">
      <mat-icon class="user-menu-icon">person</mat-icon>
      <span data-dd-privacy="mask" class="user-menu-text"
        >{{ userData.givenName }} {{ userData.familyName }}</span
      >
    </div>
    <div disabled="disabled" mat-menu-item class="user-menu-item">
      <mat-icon class="user-menu-icon" style="overflow: visible;">email</mat-icon>
      <span data-dd-privacy="mask" class="user-menu-text">
        {{ userData.email }}
      </span>
    </div>
    <div mat-menu-item (click)="downloadDriver()" class="user-menu-item">
      <mat-icon class="user-menu-icon">cloud_download</mat-icon>
      <span
        class="user-menu-text"
        *ngIf="customTranslatorService.userLocaleData"
        i18n="@@app_app_driver"
        [textContent]="'app_app_driver' | translate"
        >Software Drivers</span
      >
    </div>
    <div *ngIf="showPsSync" mat-menu-item (click)="syncPsReports()" class="user-menu-item">
      <mat-icon class="user-menu-icon">sync</mat-icon>
      <span
        class="user-menu-text"
        *ngIf="customTranslatorService.userLocaleData"
        i18n="@@app_app_sync"
        [textContent]="'app_app_sync' | translate"
        >Sync PS Reports</span
      >
    </div>
    <div mat-menu-item (click)="openHelp()" class="user-menu-item">
      <mat-icon class="user-menu-icon">help</mat-icon>
      <span
        class="user-menu-text"
        *ngIf="customTranslatorService.userLocaleData"
        i18n="@@app_app_help"
        [textContent]="'app_app_help' | translate"
        >Help</span
      >
    </div>
    <div mat-menu-item (click)="openAboutDialog()" class="user-menu-item">
      <mat-icon class="user-menu-icon">info</mat-icon>
      <span
        class="user-menu-text"
        *ngIf="customTranslatorService.userLocaleData"
        i18n="@@app_app_about"
        [textContent]="'app_app_about' | translate"
        >About</span
      >
    </div>
    <ng-content select="[headerOptionalMenuItem]"></ng-content>
    <div mat-menu-item id="Route-Logout" (click)="logout()" class="user-menu-item">
      <mat-icon class="user-menu-icon">power_settings_new</mat-icon>
      <span
        class="user-menu-text"
        *ngIf="customTranslatorService.userLocaleData"
        i18n="@@app_app_logout"
        [textContent]="'app_app_logout' | translate"
        >Logout</span
      >
    </div>
  </ng-container>
</xg-header>
<div class="alert-container" [cdkPortalOutlet]="alerts"></div>
<xg-sidenav
  [navContent]="sideNavContent"
  (toggleChanged)="toggleSideNav($event)"
>
</xg-sidenav>
