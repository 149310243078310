/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { AmplifyService } from '../../../aws/amplify/amplify.service';
import { XGSRole } from '@common/models/user-management.model';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

/**
 * Service for managing role audit trails, including creating audit entries for role creation, edits, and deletions.
 */
@Injectable({
  providedIn: 'root'
})
export class RoleAuditTrailService {
  constructor(
    private readonly amplifyService: AmplifyService,
    private readonly datadogService: DatadogService
  ) {}

  /**
   * Creates an audit trail entry for adding a new role.
   *
   * @param role - The role being added.
   * @param changed_by - The email of the user who made the changes.
   * @param success - The success status of the add operation.
   * @param roleName - The name of the role being added.
   * @param customer - The optional customer ID associated with the role.
   * @returns A promise that resolves to true if the audit entry was successfully added, false otherwise.
   */
  async addRoleAuditHelper(
    role: XGSRole,
    changed_by: string,
    success: string,
    roleName: string,
    customer?: string
  ): Promise<boolean> {
    const altered_value = JSON.parse(JSON.stringify(role));
    altered_value['name'] = roleName;
    delete altered_value['description'];
    let customer_id = [];
    if (customer) {
      customer_id.push(customer);
    } else {
      customer_id = [role.customerId];
    }
    const params = {
      changed_by,
      success,
      role_action: 'Role Created',
      role_altered: roleName,
      altered_value: JSON.stringify(altered_value),
      initial_value: JSON.stringify({}),
      alteration_timestamp: JSON.stringify(new Date()),
      customer_id
    };

    try {
      await this.addRoleTrail(params);

      return true;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error Adding to Role Audit Trail'
      });

      return false;
    }
  }

  /**
   * Creates an audit trail entry for deleting a role.
   *
   * @param initialValue - The initial role values before deletion.
   * @param changed_by - The email of the user who made the changes.
   * @param success - The success status of the delete operation.
   * @param roleName - The name of the role being deleted.
   * @param customer - The optional customer ID associated with the role.
   * @returns A promise that resolves to true if the audit entry was successfully added, false otherwise.
   */
  async deleteRoleAuditHelper(
    initialValue: XGSRole,
    changed_by: string,
    success: string,
    roleName: string,
    customer?: string
  ): Promise<boolean> {
    const initial_value = JSON.parse(JSON.stringify(initialValue));
    initial_value['name'] = roleName;
    delete initial_value['description'];
    if (initial_value['id']) {
      delete initial_value['id'];
    }
    let customer_id = [];
    if (customer) {
      customer_id.push(customer);
    } else {
      customer_id = customer_id.concat([initialValue.customerId]);
    }
    const params = {
      changed_by,
      success,
      role_action: 'Role Deleted',
      role_altered: roleName,
      altered_value: JSON.stringify({}),
      initial_value: JSON.stringify(initial_value),
      alteration_timestamp: JSON.stringify(new Date()),
      customer_id
    };

    try {
      await this.addRoleTrail(params);

      return true;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error Adding to Role Audit Trail'
      });

      return false;
    }
  }

  /**
   * Creates an audit trail entry for editing a role.
   *
   * @param initialValue - The initial role values before the edit.
   * @param changed_by - The email of the user who made the changes.
   * @param success - The success status of the edit operation.
   * @param roleName - The name of the role being edited.
   * @param alteredValue - The altered role values after the edit.
   * @returns A promise that resolves to true if the audit entry was successfully added, false otherwise.
   */
  async editRoleAuditHelper(
    initialValue: XGSRole,
    changed_by: string,
    success: string,
    roleName: string,
    alteredValue: XGSRole
  ): Promise<boolean> {
    const initial_value = JSON.parse(JSON.stringify(initialValue));
    initial_value['name'] = roleName;
    delete initial_value['description'];
    const tempInitialCustomers = [initialValue.customerId];
    const altered_value = JSON.parse(JSON.stringify(alteredValue));
    altered_value['name'] = roleName;
    delete altered_value['description'];
    const tempAlteredCustomers = [alteredValue.customerId];
    let customer_id = [];
    for (const customer of tempInitialCustomers) {
      if (!tempAlteredCustomers.includes(customer)) {
        await this.deleteRoleAuditHelper(
          initialValue,
          changed_by,
          success,
          roleName,
          customer
        );
      } else {
        customer_id.push(customer);
      }
    }
    for (const customer of tempAlteredCustomers) {
      if (!tempInitialCustomers.includes(customer)) {
        await this.addRoleAuditHelper(
          initialValue,
          changed_by,
          success,
          roleName,
          customer
        );
      } else {
        customer_id.push(customer);
      }
    }
    if (
      JSON.stringify(altered_value['permissions'].sort()) ===
      JSON.stringify(initial_value['permissions'].sort())
    ) {
      return true;
    }
    customer_id = [...new Set(customer_id)];
    const params = {
      changed_by,
      role_action: 'Role Edited',
      success,
      altered_value: JSON.stringify(altered_value),
      initial_value: JSON.stringify(initial_value),
      alteration_timestamp: JSON.stringify(new Date()),
      role_altered: roleName,
      customer_id
    };

    try {
      await this.addRoleTrail(params);

      return true;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error Adding to Role Audit Trail'
      });

      return false;
    }
  }

  /**
   * Makes an API call to add an entry to the role audit trail.
   *
   * @param body - The request body containing the audit items to be added.
   * @returns A promise that resolves to the API response.
   */
  async addRoleTrail(body: Object): Promise<Object> {
    const request = { body };

    return this.amplifyService.callAPI(
      'post',
      'API_UDL_UM_AUDIT_TRAIL',
      '/add-role-trail',
      request
    );
  }

  /**
   * Retrieves role audit trail entries from the API.
   *
   * @param body - The request body containing the parameters for the API call.
   * @returns A promise that resolves to the role audit trail entries.
   */
  async getRoleTrail(body: Object): Promise<Object> {
    const request = { body };

    return this.amplifyService.callAPI(
      'post',
      'API_UDL_UM_AUDIT_TRAIL',
      '/get-role-trail',
      request
    );
  }
}
