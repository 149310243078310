/* eslint-disable @typescript-eslint/naming-convention */
export interface CustomerConfig {
  customer_id: string;
  alternate_customer_ids: Record<string, string>;
  business_name: string;
  cluster_nickname: string;
  data_archive_description: string;
  db_schema_name: string;
  is_enabled_electric: boolean;
  is_enabled_gas: boolean;
  is_enabled_rni_data: boolean;
  is_enabled_water: boolean;
  is_xl: boolean;
  last_modified_by: string;
  last_modified_date: Date;
  query_update_number_of_days: any;
  region: string;
  requires_s2s_vpn: boolean;
  update_status: UpdateStatus;
  years_to_keep_in_redshift: number;
  access?: string[];
}

export enum UpdateStatus {
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  IN_PROGRESS = 'update in progress'
}
