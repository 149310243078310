<form [formGroup]="OldModels">
    <span>Available Models</span>
    <mat-error *ngIf="noModelsErrorMessageFlag" >{{noModelsErrorMessage}}</mat-error>
    <!--p *ngIf="modelIsReadyFlag">{{modelISReadyMessage}}</p-->
    <p [hidden]="modelIsReadyFlag">{{modelISReadyMessage}}</p>
    <mat-form-field appearance="fill">
        <mat-label>Previously Created Models</mat-label>
        <mat-select formControlName="oldModelsList" [disabled]="OldModelsDropdownDiabled" (selectionChange)="modelChangeEvent()">
            <mat-option *ngFor="let model of old_models_list" [value]="model">{{model}}</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-button type="submit" class="model-status-btn" (click)="checkModelStatus()">Fetch Status</button>
    <button mat-button type="button" class="retrieve-data-btn" [disabled]="retriveDataButtonDisabled" (click)="retriveData()">Retrive Data</button>
    
    
</form>
