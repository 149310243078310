
<div fxLayout="row" *ngIf='scatterplotEnabled' style="margin-right: 20px; width: 95%;">
    <plotly-plot class="plotlyscatter" [style]="{'width': '100%'}" [data]="graph_scatter.data" [layout]="graph_scatter.layout" [useResizeHandler]="true"></plotly-plot>

    
    <p class="center">Meter Size:{{meterSize}}&nbsp;&nbsp;Start Date:{{startDate}}&nbsp;&nbsp;End Date:{{endDate}}&nbsp;&nbsp;No Of Clusters:{{clusteringConstant}}</p>

    <div>
        <form [formGroup]="exportDataForm">
            <mat-form-field appearance="fill" class="export-selector">
                <mat-label>Select Clusters to Export</mat-label>
                <mat-select multiple formControlName="clustersToExport">
                    <mat-option *ngFor="let cluster of clustersArray" [value]="cluster">{{cluster}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-button type="submit" class="model-status-btn" (click)="exportToCSV()">Export</button>
        </form>
    </div>

    <button mat-button class="model-status-btn" (click)="buildMap2()">SHOW METERS ON MAP</button>

</div>
<div>
    <div *ngIf="mapLegendLoaded" id="map-legend">
        <span><b>Height of Points-</b>Represents consumption value</span><br>
        <span><b>Color-</b> Represents cluster associated with meter</span>
    </div>
    <div [hidden]="mapLoaded" #viewDiv id="viewDiv"></div>
</div>

