import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {
  subject = new Subject<any>();

  firstTimeInvocationObservable = new BehaviorSubject('');
  customerChangeEventObservable = new BehaviorSubject('');

  customerChangeEventListner =
    this.customerChangeEventObservable.asObservable();

  firstTimeInvocationListner =
    this.firstTimeInvocationObservable.asObservable();

  private dataArrayFromPopup: string[] = [];

  constructor() {}

  // User selecteion from Popup Data is send from this method
  getDatafromPopUp() {
    return this.dataArrayFromPopup.length === 0
      ? ['URLRefresh']
      : this.dataArrayFromPopup;
  }

  // User selecteion from Popup Data is recieved in this method
  sendDatafromPopUp(dataArray): void {
    this.dataArrayFromPopup = dataArray;
  }

  // Recieving data for scatterplots after clicking on retrieve data
  scatterplotDataEvent(data): void {
    this.subject.next(data);
  }

  resetOnCustomerChange(newCustomerName): void {
    this.customerChangeEventObservable.next(newCustomerName);
  }

  resetOnFirstTimeInvocation(customerName): void {
    this.firstTimeInvocationObservable.next(customerName);
  }
}
