<mat-spinner *ngIf="summaryCounts.length == 0 && loadingData == true" style="margin-left: 45%; margin-top: 5%;"></mat-spinner>

<div id="querySummary" >
    <div content>
        <div style="display: flex; flex-grow: grow">
                <xg-summary-counts *ngIf="summaryCounts.length > 0"  [summaryCounts]="summaryCounts" style="margin-top: 2.5%; margin-bottom: 2.5%; padding: 30px; 
                border-radius: 0.5rem; border-style: solid; border-width: .75px; border-color: rgb(176, 179, 179); "></xg-summary-counts>
        </div>
    </div>
</div>



<table *ngIf="summaryCounts.length > 0 && customTranslatorService.userLocaleData" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Table Column -->
    <ng-container matColumnDef="rows">
    <th mat-header-cell *matHeaderCellDef i18n="@@udl-usage_query-history_row" [textContent]="'udl-usage_query-history_row' | translate"> Rows Scanned </th>
    <td mat-cell *matCellDef="let element"> {{element.rows | number}} </td>
    </ng-container>

    <!-- Storage Column -->
    <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef i18n="@@udl-usage_query-history_data" [textContent]="'udl-usage_query-history_data' | translate"> Data Scanned </th>
    <td mat-cell *matCellDef="let element"> {{element.data}} </td>
    </ng-container>

    <!-- Rows Column -->
    <ng-container matColumnDef="query">
    <th mat-header-cell *matHeaderCellDef i18n="@@udl-usage_query-history_query" [textContent]="'udl-usage_query-history_query' | translate"> SQL Query Text </th>
    <td mat-cell *matCellDef="let element"> {{element.query}} </td>
    </ng-container>

    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef i18n="@@udl-usage_query-history_user" [textContent]="'udl-usage_query-history_user' | translate"> User Name </th>
        <td data-dd-privacy="mask" mat-cell *matCellDef="let element"> {{element.user}} </td>
    </ng-container>

    <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef> {{customTranslatorService.translate(null, 'udl-usage_query-history_start')}} ({{xgsCustomer | abbrevateZone}})</th>
        <td mat-cell *matCellDef="let element"> {{element.start | timezoneConversion:xgsCustomer}} </td>
    </ng-container>

    <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef> {{customTranslatorService.translate(null, 'udl-usage_query-history_end')}} ({{xgsCustomer | abbrevateZone}})</th>
        <td mat-cell *matCellDef="let element"> {{element.end | timezoneConversion:xgsCustomer}} </td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef i18n="@@udl-usage_query-history_duration" [textContent]="'udl-usage_query-history_duration' | translate"> Duration (s) </th>
        <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="noData" *matNoDataRow>
        <td class="noData-cell" [attr.colspan]="displayedColumns.length" i18n="@@udl-usage_query-history_query_table" [textContent]="'udl-usage_query-history_query_table' | translate">
            No Queries Found for
        </td>
        <span> {{customer_id.toUpperCase()}} </span>
    </tr>
</table>
<mat-paginator 
                 #paginator
                 [pageSize]="getPageSize()"
                 [pageSizeOptions]="[25, 50, 100]"
                 (page)="setPageLength($event)"
                 showFirstLastButtons 
                 aria-label="Select page of queries">
</mat-paginator>