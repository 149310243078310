import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-id-change-popup',
  templateUrl: './customer-id-change-popup.component.html',
  styleUrls: ['./customer-id-change-popup.component.scss']
})
export class CustomerIdChangePopupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
