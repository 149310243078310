import { Component, OnInit } from '@angular/core';
import { DateConversionsService } from '../shared/shared.module';

@Component({
  selector: 'customer-onboard',
  templateUrl: './customer-onboard.component.html',
  styleUrls: ['./customer-onboard.component.scss']
})
export class CustomerOnboardComponent implements OnInit {
  constructor(
    private readonly dateConversionsService: DateConversionsService
  ) {}

  ngOnInit(): void {}
}
