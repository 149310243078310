import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { AbstractSecurityStorage, AuthModule } from 'angular-auth-oidc-client';
import { SharedModule } from 'app/shared/shared.module';
import { OidcStorageService } from './oidc-storage.service';

@NgModule({
  imports: [
    SharedModule,
    AuthModule.forRoot({
      config: [
        {
          configId: 'xgs',
          authority: `https://${environment.xgsIdp}`,
          redirectUrl: `${window.location.origin}/authorize`,
          postLogoutRedirectUri: `${window.location.origin}/authorize`,
          unauthorizedRoute: '/authorize',
          clientId: environment.xgsClientID,
          scope: 'openid email profile roles offline_access',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          silentRenewUrl: `https://${environment.xgsIdp}/protocol/openid-connect/token`,
          renewUserInfoAfterTokenRenew: true,
          ignoreNonceAfterRefresh: true,
          autoUserInfo: true,
          logLevel: environment.logLevel,
          renewTimeBeforeTokenExpiresInSeconds: 30,
          maxIdTokenIatOffsetAllowedInSeconds: 300,
          historyCleanupOff: false
        },
        {
          configId: 'goaigua',
          authority: `https://${environment.goAiguaIdp}`,
          redirectUrl: `${window.location.origin}/authorize?referrer=goaigua`,
          postLogoutRedirectUri: `${window.location.origin}/authorize?referrer=goaigua`,
          unauthorizedRoute: '/authorize',
          clientId: environment.goAiguaClientID,
          scope: 'openid email profile roles offline_access',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          renewUserInfoAfterTokenRenew: true,
          ignoreNonceAfterRefresh: true,
          autoUserInfo: true,
          logLevel: environment.logLevel,
          renewTimeBeforeTokenExpiresInSeconds: 30,
          maxIdTokenIatOffsetAllowedInSeconds: 300,
          historyCleanupOff: false
        }
      ]
    })
  ],
  providers: [
    { provide: AbstractSecurityStorage, useClass: OidcStorageService }
  ],
  exports: [AuthModule]
})
export class AuthConfigModule {}
