import { NgModule } from '@angular/core';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';

import { BlankHomepageComponent } from './blank-homepage/blank-homepage.component';
import { ClusterAnalysisHomepageComponent } from './cluster-analysis-homepage/cluster-analysis-homepage.component';
import { CustomerIdChangePopupComponent } from './customer-id-change-popup/customer-id-change-popup.component';
import { OldModelsComponent } from './old-models/old-models.component';
import { PopupComponent } from './popup/popup.component';
import { ScatterplotComponent } from './scatterplot/scatterplot.component';
import { ClusterAnanlysisRoutingModule } from './cluster-analysis-routing.module';
import { DataTransferService } from './service/data-transfer.service';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    BlankHomepageComponent,
    ClusterAnalysisHomepageComponent,
    CustomerIdChangePopupComponent,
    OldModelsComponent,
    PopupComponent,
    ScatterplotComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PlotlyModule,
    ClusterAnanlysisRoutingModule
  ],
  providers: [DataTransferService]
})
export class ClusterAnalysisModule {}
