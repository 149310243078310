<mat-spinner *ngIf="summaryCounts.length == 0 && loadingData == true" style="margin-left: 45%; margin-top: 5%;"></mat-spinner>

<div id="totalStorage" >
  <div content>
      <div style="display: flex; flex-grow: grow">
              <xg-summary-counts *ngIf="summaryCounts.length > 0" [summaryCounts]="summaryCounts" style="margin-top: 2.5%; margin-bottom: 2.5%; padding: 30px; 
              border-radius: 0.5rem; border-style: solid; border-width: .75px; border-color: rgb(176, 179, 179); "></xg-summary-counts>
      </div>
  </div>
</div>

<table *ngIf="summaryCounts.length > 0 && customTranslatorService.userLocaleData" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Table Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef i18n="@@udl-usage_table-storage_name" [textContent]="'udl-usage_table-storage_name' | translate"> Table Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Storage Column -->
    <ng-container matColumnDef="storage">
      <th mat-header-cell *matHeaderCellDef i18n="@@udl-usage_table-storage_storage" [textContent]="'udl-usage_table-storage_storage' | translate"> Total Storage (MB) </th>
      <td mat-cell *matCellDef="let element"> {{element.storage | number}} </td>
    </ng-container>

    <!-- Rows Column -->
    <ng-container matColumnDef="rows">
      <th mat-header-cell *matHeaderCellDef i18n="@@udl-usage_table_storage_rows" [textContent]="'udl-usage_table_storage_rows' | translate"> Rows </th>
      <td mat-cell *matCellDef="let element"> {{element.rows | number}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>