/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  UDLUserData,
  UtilityCustomers,
  UtilityOption,
  UtilityOptionValue,
  XGSCustomer,
  XGSUser
} from '@common/models/user-management.model';
import { NotificationsService } from '@common/notifications/notifications.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SupersetService } from 'app/superset/superset-service/superset.service';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { UserFunctionsService } from 'app/user-management/user/user-functions-service/user-functions.service';
import { AmplifyService } from 'app/aws/amplify/amplify.service';
import { CustomerConfig } from '@common/models/customer-config';
import { ApiResponse } from '@common/models/api.model';
import { DatadogService } from '../datadog-services/datadog.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  selectedCustomer: UtilityOptionValue;
  currentCustomer: Observable<UtilityOptionValue>;
  udlCustomersConfig: CustomerConfig[] = [];
  utilityOptions = new BehaviorSubject<UtilityOption[]>([
    {
      name: ' ',
      value: { customer: {} as XGSCustomer },
      disabled: false
    }
  ]);

  private readonly selectedCustomerSource = new BehaviorSubject({
    customer: { customerId: '' } as XGSCustomer
  } as UtilityOptionValue);

  constructor(
    public dialog: MatDialog,
    private readonly notificationsService: NotificationsService,
    private readonly cookieService: CookieService,
    private readonly supersetService: SupersetService,
    private readonly userFunctionsService: UserFunctionsService,
    private readonly datadogService: DatadogService,
    private readonly router: Router,
    private readonly amplifyService: AmplifyService
  ) {
    this.currentCustomer = this.selectedCustomerSource.asObservable();
  }

  /**
   * Updates Observable with newly selected customer
   * @param customer XGS Customer
   */
  changeSelectedCustomer(customer: UtilityOptionValue): void {
    this.selectedCustomerSource.next(customer);
  }

  /**
   * Passes updated utility options to observable
   * @param options updated utility options
   */
  changeUtilityOptions(options: UtilityOption[]): void {
    this.utilityOptions.next(options);
  }

  /**
   * Resets utility options to remove 'All Customers' option
   * and sets to first customer in list if All Customers is selected
   */
  resetUtilityOptions(): void {
    const options = this.utilityOptions.getValue().filter(option => {
      if (option.name !== 'All Customers') {
        return option;
      }
    });

    const customer =
      this.selectedCustomer.customer.customerId === 'All Customers'
        ? options[0].value
        : this.selectedCustomer;
    this.onUtilityChanged(customer);
    this.changeUtilityOptions(options);
  }

  /**
   * Adds 'All Customers' to utility options for Xylem users
   * @param udlUser XGS User
   */
  addAllCustomers(udlUser: XGSUser): void {
    if (
      this.userFunctionsService.xylemRoleCheck(
        udlUser.roles[environment.xgsClientID]
      )
    ) {
      const options = this.utilityOptions.getValue();
      options.unshift({
        name: 'All Customers',
        value: { customer: { customerId: 'All Customers' } as XGSCustomer },
        disabled: false
      });

      this.changeUtilityOptions(options);
    }
  }

  /**
   * Builds Utility options and grabs first
   * customer in list to use as potential selected customer
   * @param customers list of UDL's customers from XGS
   * @returns object containing list of utility options and first customer in list
   */
  buildCustomerList(customers: XGSCustomer[]): UtilityCustomers {
    let customerPayload: UtilityCustomers = {
      selectedCustomer: {
        name: '',
        value: { customer: {} as XGSCustomer },
        disabled: false
      },
      customerList: []
    };
    for (const customer of customers) {
      if (customer.customerId === 'AllCustomers' && !this.selectedCustomer) {
        // NOTE: This is temporary until latency issue is resolved from XGS to get customers.
        customerPayload.customerList = this.buildAllCustomersList(customer);
        break;
      }

      if (customer.customerId !== 'AllCustomers') {
        const payload = this.buildCustomerPayload(customer);
        customerPayload.customerList.push(payload);
      }
    }
    customerPayload = this.finalizeCustomerPayload(customerPayload);

    return customerPayload;
  }

  /**
   * Builds the payload for a single customer, including their access configurations.
   *
   * @param customer - The customer object containing customerId and other properties.
   * @returns An object representing the customer payload with name, value, and disabled status.
   */
  buildCustomerPayload(customer: XGSCustomer): UtilityOption {
    const payload = {
      name: customer.customerId,
      value: { customer, access: [] },
      disabled: false
    };

    const udlCustomer = this.udlCustomersConfig.find(customerConfig => {
      return (
        customerConfig.customer_id.toUpperCase() ===
        customer.customerId.toUpperCase()
      );
    });

    if (udlCustomer) {
      payload.value.access = udlCustomer.access;
    }

    return payload;
  }

  /**
   * Builds a list of all eXDL customers with their respective details and access configurations
   * using data from udl_customer_config.
   *
   * @param customer - The customer object containing properties such as timezone.
   * @returns An array of UtilityOption objects representing all eXDL customers.
   */
  buildAllCustomersList(customer: XGSCustomer): UtilityOption[] {
    return this.udlCustomersConfig.map(udlCustomer => {
      return {
        name: udlCustomer.customer_id.toUpperCase(),
        value: {
          customer: {
            customerId: udlCustomer.customer_id.toUpperCase(),
            name: udlCustomer.customer_id.toUpperCase(),
            properties: {
              timezone: customer.properties?.timezone
            }
          } as XGSCustomer,
          access: udlCustomer.access
        },
        disabled: false
      };
    });
  }

  /**
   * Finalizes the customer payload by setting the selected customer and checking notifications.
   * If the customer list is empty, it initializes it with a default customer.
   *
   * @param customerPayload - The payload containing the list of customers and the selected customer.
   * @returns The finalized customer payload.
   */
  finalizeCustomerPayload(customerPayload: UtilityCustomers): UtilityCustomers {
    if (customerPayload.customerList.length > 0) {
      customerPayload.selectedCustomer = customerPayload.customerList[0];
      if (!this.selectedCustomer) {
        this.notificationsService.checkAllNotifications(
          customerPayload.selectedCustomer.value.customer
        );
      }

      return customerPayload;
    }

    customerPayload.customerList = [
      {
        name: '',
        value: { customer: { customerId: '' } as XGSCustomer },
        disabled: false
      }
    ];

    return customerPayload;
  }

  /**
   * Returns customer to be selected for customer drop down
   * but will utilize customer stored in cookie if present to handle page refreshes
   * @param customerPayload contains list of XGS customers and would be selected customer
   * @returns customer to be selected from dropdown
   */
  selectCustomer(customerPayload: UtilityCustomers): UtilityOptionValue {
    if (this.cookieService.check(`${environment.environmentName}_customer`)) {
      let selectedCustomer: UtilityOptionValue;

      const cookieCustomer = this.cookieService.get(
        `${environment.environmentName}_customer`
      );
      const customerMatch = customerPayload.customerList.find(customer => {
        return (
          customer.value?.customer?.customerId?.toUpperCase() ===
          cookieCustomer?.toUpperCase()
        );
      });

      if (customerMatch) {
        selectedCustomer = customerMatch.value;
      }

      return selectedCustomer
        ? selectedCustomer
        : customerPayload.selectedCustomer.value;
    } else {
      return customerPayload.selectedCustomer.value;
    }
  }

  /**
   * Generates a list of unique customers using user's timezone.
   *
   * @param userData - The user data containing roles and customers.
   * @returns - A list of unique customers with timezone information.
   */
  tempCustomerList(userData: UDLUserData): XGSCustomer[] {
    // NOTE: This is temporary until latency issue is resolved from XGS to get customers.
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const allRoleCustomers: string[] = Object.values(userData?.xgsRoles).flat();
    const deDupedCustomers: XGSCustomer[] = Array.from(
      new Set(allRoleCustomers)
    ).map(customer => {
      return {
        customerId: customer,
        name: customer,
        properties: {
          timezone: userTimezone
        }
      } as XGSCustomer;
    });

    return deDupedCustomers;
  }

  /**
   * Initializes customer/utility options
   * for user and emits changes to Superset
   * @param xgsCustomers
   */
  initUserCustomers(xgsCustomers: XGSCustomer[]): void {
    const customerPayload = this.buildCustomerList(xgsCustomers);
    this.selectedCustomer = this.selectCustomer(customerPayload);
    this.changeSelectedCustomer(this.selectedCustomer);
    this.emitCustomerChanged(
      this.selectedCustomer.customer?.customerId?.toUpperCase()
    );
    this.utilityOptions.next(customerPayload.customerList);
  }

  /**
   * Finds XGS/UDL Customer
   * @param customerID customer ID
   * @returns UDL customer that matches customer ID if present
   */
  validateCustomer(customerID: string): UtilityOptionValue {
    const customer = this.utilityOptions.getValue().find(utility => {
      return utility.value.customer.customerId === customerID.toUpperCase();
    });

    return customer ? customer.value : null;
  }

  /**
   * With change of utility triggers notifications for that customer,
   * if user is in superset dialog to confirm change,
   * and emits change for superset
   * @param event Selection from customer/utility dropdown
   */
  onUtilityChanged(event: UtilityOptionValue): void {
    // If this is the current customer, do nothing
    if (
      this.selectedCustomer?.customer?.customerId &&
      event.customer.customerId.toUpperCase() ===
        this.selectedCustomer.customer.customerId.toUpperCase()
    ) {
      return;
    }

    // If the user is currently on the superset page and is in the sql_lab we
    // need to confirm the customer change. Otherwise, just change the customer.
    if (
      this.router.url === '/superset' &&
      localStorage.getItem('supersetPath')?.includes('sqllab')
    ) {
      this.confirmCustomerChange(event);
    } else {
      this.selectedCustomer = event;
      if (event.customer.customerId !== 'All Customers') {
        this.emitCustomerChanged(event.customer.customerId.toUpperCase());
        this.notificationsService.checkAllNotifications(event.customer);
      }
      this.changeSelectedCustomer(event);
    }
  }

  /**
   * Provides dialog for user to confirm customer
   * change to not loose work/place in Superset
   * @param customer customer/utility to select
   */
  confirmCustomerChange(customer: UtilityOptionValue): void {
    const prevCustomer = this.selectedCustomer;
    this.selectedCustomer = customer;

    // Open the confirmation dialog
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: {
        title: 'Are you sure?',
        // eslint-disable-next-line max-len
        description:
          'Changing customers requires refreshing Superset. If you have any unsaved queries, please save them before continuing.',
        showCancelButton: true
      }
    });

    // If the user confirms, emit the customer changed event
    // Otherwise, revert the customer selection and update the UI
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.emitCustomerChanged(
          this.selectedCustomer.customer.customerId.toUpperCase()
        );
        this.changeSelectedCustomer(customer);
        this.notificationsService.checkAllNotifications(
          this.selectedCustomer.customer
        );
      } else {
        this.selectedCustomer = prevCustomer;
      }
    });
  }

  /**
   * Sets customer cookie, removes previous customer
   * superset token, emit customer change for superset
   * @param customer new customer ID selected
   */
  async emitCustomerChanged(customer: string): Promise<void> {
    // Set customer cookie for superset to use
    this.cookieService.set(
      `${environment.environmentName}_customer`,
      customer,
      null,
      '/',
      this.supersetService.domainHelper(),
      true,
      'None'
    );

    // Remove the superset-auth token to update customer change
    this.supersetService.invalidateToken();

    if (customer && customer !== '') {
      await this.supersetService.checkSupersetAuth();
    }

    // Dispatch customer changed window event
    const custChangeEvent = new Event('customerChanged');
    window.dispatchEvent(custChangeEvent);
  }

  /**
   * Sets the UDL customers configuration if it is not already set.
   */
  async setUdlCustomersConfig(): Promise<void> {
    try {
      if (this.udlCustomersConfig.length === 0) {
        const response = await this.getCustomerConfig(['EMPTY'], true);
        this.udlCustomersConfig = response.data as CustomerConfig[];
        this.udlCustomersConfig.sort((x, y) => {
          return x.customer_id.localeCompare(y.customer_id);
        });
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error getting XDL customers config'
      });
    }
  }

  /**
   * Retrieves the first customer with access to a specific feature and is not disabled.
   *
   * @param feature - The feature to check access for.
   * @returns - The customer with access to the feature, or undefined if none found.
   */
  getCustomerWithAccess(feature: string): UtilityOption {
    return this.utilityOptions.getValue().find(customer => {
      return customer.value.access?.includes(feature) && !customer.disabled;
    });
  }

  /**
   * Disables customers who do not have access to a specific feature.
   *
   * @param feature - The feature to check access for.
   */
  disableCustomersWithNoAccess(feature: string): void {
    this.utilityOptions.getValue().forEach(utilityOption => {
      if (!utilityOption.value.access?.includes(feature)) {
        utilityOption.disabled = true;
      }
    });
  }

  /**
   * Enables access for all customers by setting the
   * `disabled` property to `false` for each utility option.
   */
  enableCustomersAccess(): void {
    this.utilityOptions.getValue().forEach(utilityOption => {
      utilityOption.disabled = false;
    });
  }

  /**
   * Gets customer configuration
   * @param customerId (optional) customer ID - if not provided, uses globally selected customer (UDL Customer Dropdown)
   * @param userCustomers (optional) flag to get user's customers
   * @returns customer configuration
   */
  async getCustomerConfig(
    customerId?: string[],
    userCustomers?: boolean
  ): Promise<ApiResponse> {
    const queryParams: { customer_id: string[]; user?: boolean } = {
      customer_id: customerId || [this.selectedCustomer.customer.customerId]
    };

    if (userCustomers) {
      queryParams.user = userCustomers;
    }

    return this.amplifyService.callAPI<ApiResponse>(
      'get',
      'customer-onboard',
      '/get_customer_info',
      {
        queryParams
      }
    );
  }
}
