import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { AmplifyService } from '../../aws/amplify/amplify.service';
import { MatSort } from '@angular/material/sort';
import { LoadingService } from '@gravity-angular/layout';
import { MatDialog } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { S2sLoginMgmtService } from 'app/s2s-connection-mgmt/s2s-login-mgmt.service';
import { AlertsService } from '@gravity-angular/base';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';
import { CustomerConfig, UpdateStatus } from '@common/models/customer-config';
import { ColorType } from '@gravity-angular/models';
import { UDLUserData } from '@common/models/user-management.model';
import { FeatureFlag } from 'app/shared/services/feature-flags/feature-flags.service';
import { UmService } from 'app/auth/um-service/um.service';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

@Component({
  selector: 'app-add-edit-customer-config',
  templateUrl: './add-edit-customer-config.component.html',
  styleUrls: ['./add-edit-customer-config.component.scss']
})
export class AddEditCustomerConfigComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  form: FormGroup;

  dataSourceConfigHistoryMatTable;
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  isDisabledMultipleElements = true;
  isDisabledForm = true;
  isDisabledS2sVpn = false;
  disableSubmit = false;
  isCustomerListReady = false;
  myControl = new FormControl();
  dataSourceConfigHistory = null;
  s2sVpnRequired = false;
  configUpdateStatusResponse = null;
  configStatus = 'unknown';
  custId = null;
  selectedCustomer: CustomerConfig;

  FeatureFlag = FeatureFlag;

  // Mat sort for the DDB history table
  // ***************************************************************************************************
  dataSourceClusterMatTable = new MatTableDataSource([]);
  dataSourceCluster = [];
  dataSourceClusterFiltered = [];

  submitted = false;
  newUdlCustomer = true;
  custIdCompareText = "SA id = '' and RNI id = ''";

  isSaFail = false;

  userData: UDLUserData;

  private formChangesSubscription: Subscription;

  // these 2 methods are used for all the API gateway calls in this screen
  constructor(
    private readonly amplifyService: AmplifyService,
    private readonly umService: UmService,
    private readonly loadingService: LoadingService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly s2sLoginMgmtService: S2sLoginMgmtService,
    private readonly alertsService: AlertsService,
    private readonly customerService: CustomerService,
    private readonly datadogService: DatadogService,
    public dialog: MatDialog
  ) {
    this.userData = this.umService.getUserData();
  }

  /**
   * Calls the UDL Admin UI API with the specified method, API name, path, and initialization object.
   *
   * @param method - The HTTP method to use for the API call (e.g., 'get', 'post', 'put', 'delete').
   * @param apiName - The name of the API to call.
   * @param path - The path of the API endpoint.
   * @param init - The initialization object containing request parameters and headers.
   * @returns A promise that resolves to the response object from the API call.
   */
  async udlAdminUi(
    method: string,
    apiName: string,
    path: string,
    init: object
  ): Promise<object> {
    return this.amplifyService.callAPI(method, apiName, path, init);
  }

  // Auto complete customer ID
  // ***************************************************************************************************
  async autocompleteSelectionChanged(option: string): Promise<void> {
    this.loadingService.showLoading(true);
    this.disableSubmit = true;
    this.custId = option;
    this.selectedCustomer = null;
    this.dataSourceDeploymentDetails = [];
    this.isDisabledForm = true;
    this.isDisabledMultipleElements = true;
    await this.loadDeploymentData(option);
    await this.loadFormData(option);
    this.loadingService.showLoading(false);
  }

  // method called when screen first loads
  // ***************************************************************************************************
  async ngOnInit(): Promise<void> {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value);
      })
    );
    // make apis calls now that are not dependent on customer the user selects in the auto-complete

    // get rni customers for the auto complete
    this.loadingService.showLoading(true);
    try {
      const rniResponse = await this.udlAdminUi(
        'get',
        'customer-onboard',
        '/get_all_rni_customers',
        {}
      );
      this.isCustomerListReady = true;
      this.options = JSON.parse(JSON.stringify(rniResponse));
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred retrieving RNI customers'
      });
      this.alertsService.addAlert({
        title: 'Error Retrieving RNI Customers',
        message:
          'Error occured retrieving RNI customers, if issue persists contact UDL team.',
        dismissable: true,
        type: ColorType.danger
      });
    }

    this.initForm();

    // get info for valid Redshift clusters
    try {
      const clusterResponse = await this.udlAdminUi(
        'get',
        'customer-onboard',
        '/get_cluster_info',
        {}
      );
      this.dataSourceClusterMatTable = new MatTableDataSource(
        JSON.parse(JSON.stringify(clusterResponse))
      );
      this.dataSourceCluster = JSON.parse(JSON.stringify(clusterResponse));
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred retrieving cluster info'
      });
      this.alertsService.addAlert({
        title: 'Error Retrieving Cluster Info',
        message:
          'Error occured retrieving cluster info, if issue persists contact UDL team.',
        dismissable: true,
        type: ColorType.danger
      });
    }

    // get change-log type history for the DDB customer config table
    try {
      const configResponse = await this.udlAdminUi(
        'get',
        'customer-onboard',
        '/get_config_history',
        {}
      );
      this.dataSourceConfigHistoryMatTable = new MatTableDataSource(
        JSON.parse(JSON.stringify(configResponse))
      );
      this.dataSourceConfigHistory = JSON.parse(JSON.stringify(configResponse));
      this.dataSourceConfigHistoryMatTable.sort = this.sort;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred retrieving customer config history'
      });
      this.alertsService.addAlert({
        title: 'Error Retrieving Config History',
        message:
          'Error occured retrieving customer config history, if issue persists contact UDL team.',
        dismissable: true,
        type: ColorType.danger
      });
    }
    this.loadingService.showLoading(false);
  }

  /**
   * Initializes the customer form with the given customer configuration and option.
   *
   * @param udlCustomer - The customer configuration object to initialize the form with.
   * @param option - A boolean flag to enable or disable certain form controls.
   */
  initForm(udlCustomer?: CustomerConfig, option?: boolean): void {
    this.form = new FormGroup({
      customer_id: new FormControl(
        {
          value: udlCustomer?.customer_id || null,
          disabled: true
        },
        [Validators.required]
      ),
      alternate_customer_ids: new FormGroup({
        sa: new FormControl(
          {
            value: udlCustomer?.alternate_customer_ids?.sa || '',
            disabled: true
          },
          [Validators.required]
        ),
        rni: new FormControl(
          {
            value: udlCustomer?.alternate_customer_ids?.rni || '',
            disabled: true
          },
          [Validators.required]
        )
      }),
      cluster_nickname: new FormControl(
        {
          value: udlCustomer?.cluster_nickname || null,
          disabled: true
        },
        [Validators.required]
      ),
      business_name: new FormControl(
        {
          value: udlCustomer?.business_name || null,
          disabled: true
        },
        [Validators.required]
      ),
      db_schema_name: new FormControl(
        { value: udlCustomer?.db_schema_name || null, disabled: true },
        [Validators.required]
      ),
      years_to_keep_in_redshift: new FormControl(
        {
          value: udlCustomer?.years_to_keep_in_redshift || null,
          disabled: true
        },
        [Validators.required]
      ),
      data_archive_description: new FormControl(
        {
          value: udlCustomer?.data_archive_description || null,
          disabled: true
        },
        [Validators.required]
      ),
      is_enabled_water: new FormControl(
        { value: udlCustomer?.is_enabled_water || false, disabled: !option },
        [Validators.required]
      ),
      is_enabled_electric: new FormControl(
        { value: udlCustomer?.is_enabled_electric || false, disabled: !option },
        [Validators.required]
      ),
      is_enabled_gas: new FormControl(
        { value: udlCustomer?.is_enabled_gas || false, disabled: !option },
        [Validators.required]
      ),
      requires_s2s_vpn: new FormControl(
        { value: udlCustomer?.requires_s2s_vpn || false, disabled: !option },
        [Validators.required]
      ),
      is_enabled_rni_data: new FormControl(
        { value: udlCustomer?.is_enabled_rni_data || false, disabled: !option },
        [Validators.required]
      ),
      access: new FormGroup({
        [FeatureFlag.DATA_IMPORT]: new FormControl({
          value:
            udlCustomer?.access?.includes(FeatureFlag.DATA_IMPORT) || false,
          disabled: !option
        })
      })
    });
    this.monitorFormChanges();
  }

  /**
   * Monitors changes in the form and enables the submit button if the form is dirty.
   */
  monitorFormChanges(): void {
    // Unsubscribe from the previous subscription if it exists
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }

    // Subscribe to the form value changes
    this.formChangesSubscription = this.form.valueChanges.subscribe(() => {
      if (this.form.dirty) {
        this.disableSubmit = false;
      }
    });
  }

  /**
   * Generates a list of access keys from the given access object.
   * Used to create access list for DDB table
   *
   * @param access - The access object containing key-value pairs.
   * @returns An array of keys where the corresponding value is truthy.
   */
  generateAccessList(access: object): string[] {
    const accessList = [];
    for (const key in access) {
      if (access[key]) {
        accessList.push(key);
      }
    }

    return accessList;
  }

  /**
   * Checks if the current user is a SuperAdmin.
   *
   * @returns True if the user is not a SuperAdmin, otherwise false.
   */
  superAdminCheck(): boolean {
    return this.userData?.xgsRoles?.SuperAdmin === undefined;
  }

  // this method is called when a user changes the customer selection in the auto-complete box
  // loadFormData() is also called at this time
  // ***************************************************************************************************
  async loadDeploymentData(option: string): Promise<void> {
    this.dataSourceDeploymentDetails = null;

    try {
      const response = await this.udlAdminUi(
        'get',
        'customer-onboard',
        '/get_deployment_info',
        {
          queryParams: { customer_id: option }
        }
      );
      this.dataSourceDeploymentDetails = [response];
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred retrieving deployment info'
      });
      this.alertsService.addAlert({
        title: 'Error Retrieving Deployment Info',
        message: `Error retrieving deployment info for customer ${option}`,
        dismissable: true,
        type: ColorType.danger
      });
    }
  }

  /**
   * Initializes a new customer form with the given option.
   *
   * @param option - The customer ID to set in the form.
   */
  newCustomer(option: string) {
    this.initForm(undefined, true);
    this.form.get('customer_id').setValue(option);
  }

  // this method is called when a user changes the customer selection in the auto-complete box
  // loadDeploymentData() is also called at this time
  // ***************************************************************************************************
  async loadFormData(option: string): Promise<void> {
    // An api call is made to load data for the form

    try {
      const customerResponse = await this.customerService.getCustomerConfig([
        option.toLowerCase()
      ]);
      this.selectedCustomer =
        customerResponse.data[0] || ({} as CustomerConfig);

      this.isDisabledMultipleElements = false;
      this.isDisabledS2sVpn = false;

      // different formatting for the cluster field depending on if it is enabled or disabled
      if (!this.selectedCustomer?.customer_id) {
        this.newUdlCustomer = true;
        this.newCustomer(option);
      } else {
        this.newUdlCustomer = false;
        this.initForm(this.selectedCustomer, true);
        this.s2sVpnRequired = this.selectedCustomer.requires_s2s_vpn;
        if (this.s2sVpnRequired) {
          await this.getS2sLogins(this.selectedCustomer.customer_id);
        }
      }

      this.isSaFail = false;
      const adminCheckResult = this.superAdminCheck();
      this.isDisabledForm = adminCheckResult;
      this.isDisabledMultipleElements = adminCheckResult;
      this.custIdCompareText = 'PASSED';
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred retrieving customer config'
      });
      this.alertsService.addAlert({
        title: 'Error Retrieving Customer Config',
        message: `Error retrieving customer config for customer ${option}`,
        dismissable: true,
        type: ColorType.danger
      });
    }
  }

  /**
   * Retrieves S2S logins for the specified customer and updates the state.
   *
   * @param customerId - The ID of the customer to retrieve S2S logins for.
   * @returns A promise that resolves when the operation is complete.
   */
  async getS2sLogins(customerId: string): Promise<void> {
    try {
      const s2sLoginResp = await this.s2sLoginMgmtService.getLogins(customerId);
      this.s2sLoginsChanged(s2sLoginResp.length);
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred retrieving S2S logins'
      });
      this.alertsService.addAlert({
        title: 'Error Retrieving S2S Logins',
        message: `Error retrieving S2S logins for customer ${customerId}`,
        dismissable: true,
        type: ColorType.danger
      });
    }
  }

  /**
   * Handles the form submission process for customer onboarding or editing.
   * Disables the form and submit button, shows a loading indicator, and updates the customer information.
   * Waits for the update status to change from IN_PROGRESS, and shows alerts if there are issues.
   */
  async onSubmit(): Promise<void> {
    this.newUdlCustomer = false;
    this.selectedCustomer.update_status = UpdateStatus.IN_PROGRESS;

    this.loadingService.showLoading(true);
    this.isDisabledForm = true;
    this.disableSubmit = true;
    this.isDisabledMultipleElements = true;

    this.submitted = true;

    const datum = new Date();

    const user = this.userData.email;
    await lastValueFrom(this.oidcSecurityService.forceRefreshSession());
    const customerParams = this.form.getRawValue();
    customerParams.customer_id = customerParams.customer_id.toLowerCase();
    customerParams['last_modified_by'] = user;
    customerParams['last_modified_date'] = datum.toISOString();
    customerParams.access = this.generateAccessList(
      this.form.get('access').value
    );

    const params = {
      body: {
        customerParams,
        xgs_token: await lastValueFrom(
          this.oidcSecurityService.getAccessToken()
        )
      }
    };

    try {
      const response = await this.udlAdminUi(
        'put',
        'customer-onboard',
        '/put_customer_info',
        params
      );
      this.customerPutAlert(response);
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred updating customer info'
      });
    }

    // call the customer info again, should see in process
    let num = 0;
    const numSecToWait = 5;
    const iterations = 15;

    await this.delayMs(numSecToWait * 1000);
    while (num < iterations) {
      await this.loadFormData(this.custId);
      await this.delayMs(numSecToWait * 1000);
      num += 1;

      if (this.selectedCustomer.update_status !== UpdateStatus.IN_PROGRESS) {
        if (this.selectedCustomer.update_status === '' && num > 3) {
          this.alertsService.addAlert({
            title: 'Error During Customer Onboarding',
            message: `There was an issue onboarding or editing the customer ${customerParams.customer_id.toUpperCase()},
                      please contact XDL team for assistance.`,
            dismissable: true,
            type: ColorType.danger
          });
        }
        break;
      }

      if (num >= 14) {
        this.alertsService.addAlert({
          title: 'Wait Time Exceeded',
          message: `Process has waited ${
            numSecToWait * iterations
          } seconds for the update to complete and will stop checking. 
          Wait longer and re-load page to see if status is updated for customer.`,
          dismissable: true,
          type: ColorType.warn
        });
      }
    }

    this.isDisabledForm = false;
    this.disableSubmit = false;
    this.isDisabledMultipleElements = false;
    this.loadingService.showLoading(false);
  }

  /**
   * Displays an alert if the customer onboarding or editing process fails.
   *
   * @param response - The response object containing the query status and error message.
   */
  customerPutAlert(response: object): void {
    if (response['queryStatus'] === UpdateStatus.FAILED.toUpperCase()) {
      this.alertsService.addAlert({
        title: 'Customer Onboarding Failed',
        message: `There was an issue onboarding or editing the customer ${this.custId.toUpperCase()}: ${
          response['errorMsg'] || 'Unknown Error'
        }`,
        dismissable: true,
        type: ColorType.danger
      });
    }
  }

  /**
   * Delays execution for a specified number of milliseconds.
   *
   * @param ms - The number of milliseconds to delay.
   * @returns A promise that resolves after the specified delay.
   */
  async delayMs(ms: number) {
    return new Promise(resolve => {
      return setTimeout(resolve, ms);
    });
  }

  /**
   * Updates the state of the S2S VPN based on the number of S2S logins.
   *
   * @param loginCount - The number of S2S logins.
   */
  s2sLoginsChanged(loginCount: number): void {
    this.isDisabledS2sVpn =
      this.selectedCustomer.requires_s2s_vpn && loginCount > 0;
  }

  // ***************************************************************************************************
  // ***************************************************************************************************
  // Everything after this point is for data source initialization
  // ***************************************************************************************************
  // ***************************************************************************************************

  // Data source for: Deployment Details
  // ***************************************************************************************************
  displayedColumnsDeploymentDetails: string[] = [
    'customer_id',
    'customer_name',
    'market_city',
    'state',
    'status',
    'meter_type',
    'rni_name',
    'hosted_by'
  ];

  tableDefDeploymentDetails: any[] = [
    {
      key: 'customer_id',
      header: 'Customer Id'
    },
    {
      key: 'customer_name',
      header: 'Customer Name'
    },
    {
      key: 'market_city',
      header: 'Market City'
    },
    {
      key: 'state',
      header: 'State'
    },
    {
      key: 'status',
      header: 'Status'
    },
    {
      key: 'meter_type',
      header: 'Meter Type'
    },
    {
      key: 'rni_name',
      header: 'RNI Name'
    },
    {
      key: 'hosted_by',
      header: 'Hosted By'
    }
  ];

  dataSourceDeploymentDetails = null;

  // Data source for: Redshift Clusters
  // ***************************************************************************************************
  displayedColumnsCluster: string[] = [
    'cluster_identifier',
    'node_type',
    'db_name',
    'availability_zone',
    'cluster_create_time',
    'description',
    'num_customers_on_cluster'
  ];

  tableDefCluster: any[] = [
    {
      key: 'cluster_identifier',
      header: 'Cluster Identifier'
    },
    {
      key: 'node_type',
      header: 'Node Type'
    },
    {
      key: 'db_name',
      header: 'DB Name'
    },
    {
      key: 'availability_zone',
      header: 'Availability Zone'
    },
    {
      key: 'cluster_create_time',
      header: 'Cluster Create Time'
    },
    {
      key: 'description',
      header: 'Description'
    },
    {
      key: 'num_customers_on_cluster',
      header: 'Num Customers On Cluster'
    }
  ];

  // Data source for: UDL Config Change History
  // ***************************************************************************************************
  displayedColumnsConfigHistory: string[] = [
    'customer_id',
    'approximate_creation_date_time_iso8601',
    'modified_by',
    'modifications'
  ];

  tableDefConfigHistory: any[] = [
    {
      key: 'modifications',
      header: 'Modifications'
    },
    {
      key: 'approximate_creation_date_time',
      header: 'Approximate Creation Date Time'
    },
    {
      key: 'event_id',
      header: 'Event Id'
    },
    {
      key: 'customer_id',
      header: 'Customer Id'
    },
    {
      key: 'modified_by',
      header: 'Modified By'
    },
    {
      key: 'approximate_creation_date_time_iso8601',
      header: 'Approximate Creation Date Time Iso8601'
    },
    {
      key: 'old_image',
      header: 'old_image'
    },
    {
      key: 'new_image',
      header: 'New Image'
    }
  ];

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    try {
      return this.options.filter(option => {
        return option.toLowerCase().includes(filterValue);
      });
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred filtering customer options'
      });
    }
  }
}
