<div class="role-audit-list" *ngIf="content === 'role'">
    <div class="row">
        <h1 style="padding-left: 40px;">
            {{data['role']}} 
        </h1>
        <h1 style="padding-left: 10px;" i18n="@@audit-trail_audit-trail-dialog_header" [textContent]="'audit-trail_audit-trail-dialog_header' | translate">
            Role History
        </h1>
    </div>
    <mat-spinner *ngIf="!dataLoaded" mode="indeterminate" class="loading-spinner"></mat-spinner>
    <div class="table-container">
        <table class="audit-table"  [hidden]="!dataLoaded" mat-table [dataSource]="roleDataSource" matSort #roleSort="matSort">
            <!-- Customer Column -->
            <ng-container matColumnDef="customer_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_customer" [textContent]="'audit-trail_audit-trail-dialog_customer' | translate">
                        Customer 
                    </span>
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default;">
                    <span>{{element.customer_id | uppercase}}</span>
                </td>
            </ng-container>
    
            <!-- Altered By Column -->
            <ng-container matColumnDef="changed_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_AlteredBy" [textContent]="'audit-trail_audit-trail-dialog_AlteredBy' | translate">
                        Altered By 
                    </span>
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span>{{element.changed_by}}</span>
                </td>
            </ng-container>
    
            <!-- User Altered Column -->
            <ng-container matColumnDef="role_altered">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_role" [textContent]="'audit-trail_audit-trail-dialog_role' | translate">
                        Role Altered 
                    </span>
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span>{{element.role_altered}}</span>
                </td>
            </ng-container>
    
            <!-- Action Column -->
            <ng-container matColumnDef="role_action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_action" [textContent]="'audit-trail_audit-trail-dialog_action' | translate">
                        Action
                    </span>
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span>{{element.role_action}}</span>
                </td>
            </ng-container>
    
            <!-- Initial Value Column -->
            <ng-container matColumnDef="initial_value">
                <th mat-header-cell *matHeaderCellDef i18n="@@audit-trail_audit-trail-dialog_initial" [textContent]="'audit-trail_audit-trail-dialog_initial' | translate">
                    Initial Value
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span style="white-space: pre-line;">{{element.initial_value | roleAudit}}</span>
                </td>
            </ng-container>
    
            <!-- New Value Column -->
            <ng-container matColumnDef="altered_value">
                <th mat-header-cell *matHeaderCellDef i18n="@@audit-trail_audit-trail-dialog_new" [textContent]="'audit-trail_audit-trail-dialog_new' | translate">
                    New Value
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span style="white-space: pre-line;">{{element.altered_value | roleAudit}}</span>
                </td>
            </ng-container>
    
            <!-- Time Column -->
            <ng-container matColumnDef="alteration_timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_time" [textContent]="'audit-trail_audit-trail-dialog_time' | translate">
                        Time 
                    </span> 
                    ({{xgsCustomer | abbrevateZone}})
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default;">
                    <span>{{element.alteration_timestamp | timezoneConversion:xgsCustomer:true}}</span>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="roleColumnsCluster"></tr>
            <tr mat-row *matRowDef="let row; columns: roleColumnsCluster;"></tr>
    
            <tr class="noData" *matNoDataRow>
                <td class="noData-cell" [attr.colspan]="roleColumnsCluster.length" i18n="@@audit-trail_audit-trail-dialog_empty" [textContent]="'audit-trail_audit-trail-dialog_empty' | translate">
                    No User History Data Available
                </td>
            </tr>
        </table>
        <mat-paginator 
            class="user-audit-pagination"
            [hidden]="!dataLoaded" 
            [length]="roleLength" 
            #rolePaginator="matPaginator" 
            [pageSizeOptions]="[5, 10, 15, 20, 25]" 
            [pageSize]="getPageSize()"
            showFirstLastButtons>
        </mat-paginator>
        <xg-button
            class="close-button"
            buttonType="raised"
            buttonColor="primary"
            (click)="close()"
            [text]="'audit-trail_audit-trail-dialog_close' | translate">
        </xg-button>
    </div>
</div>
<div class="user-audit-list" *ngIf="content === 'user'">
    <div class="row">
        <h1 style="padding-left: 40px;" data-dd-privacy="mask">
            {{data['user']['name']}} 
        </h1>
        <h1 style="padding-left: 10px;" i18n="@@audit-trail_audit-trail-dialog_userHeader" [textContent]="'audit-trail_audit-trail-dialog_userHeader' | translate">
            User History
        </h1>
    </div>
    <mat-spinner *ngIf="!dataLoaded" mode="indeterminate" class="loading-spinner"></mat-spinner>
    <div class="table-container">
        <table class="audit-table" [hidden]="!dataLoaded" mat-table [dataSource]="userDataSource" matSort #userSort="matSort">
            <!-- Customer Column -->
            <ng-container matColumnDef="customer_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_userCustomer" [textContent]="'audit-trail_audit-trail-dialog_userCustomer' | translate">
                        Customer
                    </span> 
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default;">
                    <span class="cell-data">{{element.customer_id | uppercase}}</span>
                </td>
            </ng-container>
    
            <!-- Altered By Column -->
            <ng-container matColumnDef="changed_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_userAlteredBy" [textContent]="'audit-trail_audit-trail-dialog_userAlteredBy' | translate">
                        Altered By
                    </span> 
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span class="cell-data">{{element.changed_by}}</span>
                </td>
            </ng-container>
    
            <!-- User Altered Column -->
            <ng-container matColumnDef="user_altered">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_userAltered" [textContent]="'audit-trail_audit-trail-dialog_userAltered' | translate">
                        User Altered
                    </span>
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span class="cell-data">{{element.user_altered}}</span>
                </td>
            </ng-container>
    
            <!-- Action Column -->
            <ng-container matColumnDef="user_action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_userAction" [textContent]="'audit-trail_audit-trail-dialog_userAction' | translate">
                        Action
                    </span>
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span class="cell-data">{{element.user_action}}</span>
                </td>
            </ng-container>
    
            <!-- Initial Value Column -->
            <ng-container matColumnDef="initial_value">
                <th mat-header-cell *matHeaderCellDef i18n="@@audit-trail_audit-trail-dialog_userInitial" [textContent]="'audit-trail_audit-trail-dialog_userInitial' | translate">
                    Initial Value
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span style="white-space: pre-line;" class="cell-data">{{element.initial_value | userAudit}}</span>
                </td>
            </ng-container>
    
            <!-- New Value Column -->
            <ng-container matColumnDef="altered_value">
                <th mat-header-cell *matHeaderCellDef i18n="@@audit-trail_audit-trail-dialog_userNew" [textContent]="'audit-trail_audit-trail-dialog_userNew' | translate">
                    New Value
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default; padding: 12px 16px 12px 0;">
                    <span style="white-space: pre-line;" class="cell-data">{{element.altered_value | userAudit}}</span>
                </td>
            </ng-container>
    
            <!-- Time Column -->
            <ng-container matColumnDef="alteration_timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@audit-trail_audit-trail-dialog_userTime" [textContent]="'audit-trail_audit-trail-dialog_userTime' | translate">
                        Time
                    </span> 
                    ({{xgsCustomer | abbrevateZone}})
                </th>
                <td mat-cell *matCellDef="let element" style="cursor: default;">
                    <span class="cell-data">{{element.alteration_timestamp | timezoneConversion:xgsCustomer:true}}</span>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="userColumnsCluster"></tr>
            <tr mat-row *matRowDef="let row; columns: userColumnsCluster;"></tr>
    
            <tr class="noData" *matNoDataRow>
                <td class="noData-cell" [attr.colspan]="userColumnsCluster.length" i18n="@@audit-trail_audit-trail-dialog_userEmpty" [textContent]="'audit-trail_audit-trail-dialog_userEmpty' | translate">
                    No User History Data Available
                </td>
            </tr>
        </table>
        <mat-paginator 
            class="user-audit-pagination"
            [hidden]="!dataLoaded" 
            [length]="userLength" 
            #userPaginator="matPaginator" 
            [pageSizeOptions]="[5, 10, 15, 20, 25]" 
            [pageSize]="getPageSize()"
            showFirstLastButtons>
        </mat-paginator>
        <xg-button
            class="close-button"
            buttonType="raised"
            buttonColor="primary"
            (click)="close()"
            [text]="'audit-trail_audit-trail-dialog_close' | translate">
        </xg-button>
    </div>
</div>  