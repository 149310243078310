<mat-card>
  <xg-tabs>
    <mat-tab label="{{ currentLogins }}/{{ maxLogins }} logins in use">
      <div>
        <h5>
          <ng-container *ngIf="canAddLogin">
            <button mat-button class="add-button" (click)="addLogin()" [disabled]="disabled || !canEdit">
              Add Login
            </button>
          </ng-container>
          <ng-container *ngIf="!canAddLogin">
            You have reached your maximum number of logins
          </ng-container>
        </h5>
      </div>

      <table 
        mat-table 
        class="mat-elevation-z8"
        [dataSource]="loginsDatasource" 
        matSort 
        #loginsTable="matSort" 
        matSortActive="last_modified_on" 
        matSortDirection="desc">
        <ng-container *ngFor="let def of loginsTableDef">
          <ng-container [matColumnDef]="def.key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{def.header}}</th>
            <td [ngClass]="def.className" mat-cell *matCellDef="let element">
              {{element[def.key]}}
            </td>
          </ng-container>
        </ng-container>
        <ng-container matColumnDef="resetPassword">
          <th mat-header-cell *matHeaderCellDef>Reset Password</th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button (click)="resetPassword(element)" [disabled]="disabled || !canEdit">
              <mat-icon>sync</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="deleteLogin">
          <th mat-header-cell *matHeaderCellDef>Delete</th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button (click)="deleteLogin(element)" [disabled]="disabled || !canEdit">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="loginsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: loginsDisplayedColumns"></tr>
      </table>
    </mat-tab>

    <mat-tab label="Login Change History">
      <table 
        mat-table 
        class="mat-elevation-z8"
        [dataSource]="loginsHistoryDatasource" 
        matSort 
        #loginsHistoryTable="matSort" 
        matSortActive="approximate_creation_date_time_iso8601" 
        matSortDirection="desc">
        <ng-container *ngFor="let def of loginsHistoryTableDef">
          <ng-container [matColumnDef]="def.key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{def.header}}</th>
            <td [ngClass]="def.className" mat-cell *matCellDef="let element">
              {{element[def.key]}}
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="loginsHistoryDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: loginsHistoryDisplayedColumns"></tr>
      </table>
      <mat-paginator 
        #loginsHistoryPaginator="matPaginator" 
        [pageSizeOptions]="[10, 20, 50]"
        showFirstLastButtons 
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </mat-tab>
  </xg-tabs>
</mat-card>
