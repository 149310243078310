/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { DataTransferService } from '../service/data-transfer.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { DeckLayer } from '@deck.gl/arcgis';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import { ModalService } from '@gravity-angular/components';
import { Subscription } from 'rxjs';
import { NotificationsService } from '@common/notifications/notifications.service';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';

@Component({
  selector: 'app-scatterplot',
  templateUrl: './scatterplot.component.html',
  styleUrls: ['./scatterplot.component.scss']
})
export class ScatterplotComponent implements OnInit, OnDestroy {
  // map variables
  @ViewChild('viewDiv') viewDiv: ElementRef;
  @ViewChild('modal') modal: ElementRef;

  scatterplotEnabled = false;
  yAxisoptionName;
  flexnet_id = [];
  meter_id = [];
  lat = [];
  long = [];
  yAxisoption = [];
  consumption = [];
  clusters_number = [];
  meterSize;
  UOM;
  startDate;
  endDate;
  clusteringConstant;
  // graph_scatter
  graph_scatter = { data: [], layout: {} };

  graph_legend;

  exportDataForm: FormGroup;
  clustersArray;
  completedata;
  color_map_obj = {};

  subscription: Subscription;

  layer = new DeckLayer({});
  mapView = new MapView({});
  map = new Map({});
  mapLegendLoaded: boolean;
  mapLoaded: boolean;

  constructor(
    private readonly dataTransferService: DataTransferService,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: ModalService,
    private readonly customerService: CustomerService,
    private readonly notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    // Listens for even when Retrive Data button is clicked
    this.subscription = this.dataTransferService.subject.subscribe(value => {
      this.plottingDataParser(value);
    });

    // Listens for event when customer Name is changed
    this.subscription =
      this.dataTransferService.customerChangeEventListner.subscribe(
        customerName => {
          if (customerName.length === 0) {
            return;
          } else {
            this.scatterplotEnabled = false;
            this.mapLegendLoaded = false;
            this.mapLoaded = true;
          }
        }
      );

    this.exportDataForm = this.formBuilder.group({
      clustersToExport: []
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async plottingDataParser(data) {
    this.notificationsService.setLoadingState(true);
    this.mapLoaded = true;
    this.mapLegendLoaded = false;

    // Fetching the data for Prsing
    this.scatterplotEnabled = true;

    // Getting Filter values row from the data & removing the row containing filter values
    const filterRow = data.shift();

    // Assigning data coming from API to call to variable to be used while exporting data
    this.completedata = data;

    // Extracting filter values used while creating the cluster from Filter values row
    this.meterSize = filterRow[0];
    this.yAxisoptionName = filterRow[1];
    this.UOM = filterRow[2];
    this.startDate = filterRow[3];
    this.endDate = filterRow[4];
    this.clusteringConstant = filterRow[5];

    // Creating Array from clustering constant
    this.clustersArray = Array(this.clusteringConstant)
      .fill(1)
      .map((x, i) => {
        return i;
      });

    // Resetting Arrays to empty
    this.flexnet_id = [];
    this.meter_id = [];
    this.yAxisoption = [];
    this.lat = [];
    this.long = [];
    this.consumption = [];
    this.clusters_number = [];

    // Extracting columns from the data recieved
    data.map(n => {
      return this.mapingfunction(n);
    });

    this.clusters_number = this.clusters_number.splice(0);
    // Preping data for plotting
    this.graph_scatter = {
      data: [
        {
          x: this.consumption,
          y: this.yAxisoption,
          text: this.clusters_number,
          type: 'scatter',
          mode: 'markers',
          marker: { size: 12, color: this.clusters_number },
          hovertemplate:
            '<b>X: </b>%{x}<br>' +
            '<b>Y: </b>%{y}<br>' +
            '<b>Cluster Number: </b>%{text}'
        }
      ],

      layout: {
        height: 600,
        autosize: true,
        title: `${this.yAxisoptionName} Vs Consumption`,

        xaxis: {
          title: {
            text: `Total Consumption(${filterRow[2]})`,
            font: {
              family: 'Courier New, monospace',
              size: 18,
              color: '#7f7f7f'
            }
          }
        },

        yaxis: {
          title: {
            text: this.yAxisoptionName,
            font: {
              family: 'Courier New, monospace',
              size: 18,
              color: '#7f7f7f'
            }
          }
        }
      }
    };

    this.notificationsService.setLoadingState(false);
    this.generateClusterColors(this.clustersArray);
  }

  // Code for maps-Start
  buildMap2() {
    this.notificationsService.setLoadingState(true);
    // Setting the div which display map to empty
    this.viewDiv.nativeElement.innerHTML = '';
    // this.mapLegendLoaded = false;
    this.mapLoaded = false;
    this.mapLegendLoaded = true;

    // Creating objects to store co-ordinates and other information to be displayed on map
    const mapData = [];
    for (const row of this.completedata) {
      if (row[4] !== null && row[5] !== null) {
        const feat = {};
        feat['type'] = 'Feature';
        feat['properties'] = row;
        feat['geometry'] = {};
        feat['geometry']['type'] = 'Point';
        feat['geometry']['coordinates'] = [];
        feat['geometry']['coordinates'][0] = parseFloat(row[5]); // longitude
        feat['geometry']['coordinates'][1] = parseFloat(row[4]); // latitude
        mapData.push(feat);
      }
      this.notificationsService.setLoadingState(false);
    }

    // Constructing Layer Instance

    const geolayer = new GeoJsonLayer({
      id: 'meters-layer',
      data: mapData,
      extruded: true,
      opacity: 0.8,
      stroked: false,
      wireframe: true,
      // Styles
      filled: true,
      pointRadiusMinPixels: 10,
      pointRadiusScale: 100,
      getElevation: point => {
        return Math.pow(point.properties[6], 3);
      },
      getFillColor: point => {
        return this.color_map_obj[point.properties[7]];
      },
      // Interactive props
      pickable: true,
      autoHighlight: true
    });

    this.layer = new DeckLayer({
      effect: 'bloom(1.5, 0.5px, 0.1)',
      'deck.layers': [geolayer],
      'deck.onAfterRender': f => {},
      'deck.getTooltip': ({ object }) => {
        return (
          object && {
            html: `<b> Cluster ID:</b> ${object.properties[7]}<br> <b> Consumption :</b> ${object.properties[6]}`
          }
        );
      }
    });

    // In the ArcGIS API for JavaScript the MapView is responsible
    // for displaying a Map, which usually contains at least a basemap.
    // eslint-disable-next-line
    this.mapView = new MapView({
      container: 'viewDiv',
      map: new Map({
        basemap: 'streets-vector',
        layers: this.layer
      }),
      center: this.calculateCenter(this.completedata),
      zoom: 5
    });
  }

  generateClusterColors(clustersArray): void {
    for (const color in clustersArray) {
      if (clustersArray[color]) {
        this.color_map_obj[color] = this.getRandomColor();
      }
    }
  }

  getRandomColor() {
    let color = Math.floor(0x1000000 * Math.random()).toString(16);
    color = `#${color.slice(-6)}`;
    const rgbcolor = [
      parseInt(color.slice(1, 3), 16),
      parseInt(color.slice(3, 5), 16),
      parseInt(color.slice(5, 7), 16)
    ];

    return rgbcolor;
  }

  calculateCenter(coordinates: Object[]): number[] {
    if (coordinates.length < 1) {
      return [-95.7129, 37.0902];
    }
    let latitude = 0;
    let longitude = 0;
    for (const row of coordinates) {
      longitude += row[5];
      latitude += row[4];
    }
    longitude = longitude / coordinates.length;
    latitude = latitude / coordinates.length;

    latitude = latitude >= 90 ? 82 : latitude;
    latitude = latitude <= -90 ? -82 : latitude;

    longitude = longitude >= 180 ? 177 : longitude;
    longitude = longitude <= -180 ? -177 : longitude;

    return [longitude, latitude];
  }

  closeModal(): void {
    this.modalService.closeModal();
  }

  // Code for maps-End

  mapingfunction(dataArray) {
    this.flexnet_id.push(dataArray[0]);
    this.meter_id.push(dataArray[1]);
    this.yAxisoption.push(dataArray[2]);
    this.lat.push(dataArray[4]);
    this.long.push(dataArray[5]);
    this.consumption.push(dataArray[6]);
    this.clusters_number.push(dataArray[7]);
  }

  exportToCSV() {
    const selectedclusterArray =
      this.exportDataForm.get('clustersToExport').value;

    // Filtering data as per the clusters chosen to export by Users
    const data_to_export: any = [];
    this.completedata.filter(row => {
      for (const cluster of selectedclusterArray) {
        if (row[7] === cluster) {
          data_to_export.push({
            'Flexnet ID': row[0],
            'Meter ID': row[1],
            'Y Axis Option': row[2],
            Latitude: row[4],
            Longitude: row[5],
            Consumption: row[6],
            'Cluster ID': row[7]
          });

          return true;
        }
      }

      return false;
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: [
        'Flexnet ID',
        'Meter ID',
        this.yAxisoptionName,
        'Latitude',
        'Longitude',
        'Consumption',
        'Cluster ID'
      ]
    };

    new ngxCsv(data_to_export, this.yAxisoptionName, options);
  }
}
