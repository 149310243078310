import { Injectable } from '@angular/core';
import {
  CredentialsAndIdentityIdProvider,
  CredentialsAndIdentityId,
  GetCredentialsOptions
} from 'aws-amplify/auth';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Cache } from 'aws-amplify/utils';
import jwtDecode, { JwtPayload } from 'jwt-decode';

// Note: This example requires installing `@aws-sdk/client-cognito-identity` to obtain Cognito credentials
// npm i @aws-sdk/client-cognito-identity
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom } from 'rxjs';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

@Injectable({
  providedIn: 'root'
})
export class CognitoServiceService implements CredentialsAndIdentityIdProvider {
  amplifyIdp: string;
  customCredentialsProvider;
  federatedLogin?: {
    domain: string;
    token: string;
  };

  cognitoidentity = new CognitoIdentity({
    region: 'us-east-1'
  });

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly datadogService: DatadogService,
    private readonly cookieService: CookieService
  ) {
    this.amplifyIdp =
      this.cookieService.get('referrer') === 'goaigua' ? 'goaigua' : 'xgs';
  }

  // Custom method to load the federated login information
  loadFederatedLogin(login?: typeof this.federatedLogin) {
    // You may also persist this by caching if needed
    this.federatedLogin = login;
  }

  async getCredentialsAndIdentityId(
    getCredentialsOptions: GetCredentialsOptions
  ): Promise<CredentialsAndIdentityId | undefined> {
    try {
      const value: CredentialsAndIdentityId = await Cache.getItem(
        'federatedInfo'
      );

      if (value) {
        const currentTime = new Date().getTime();
        const expirationTime = new Date(
          value.credentials?.expiration
        ).getTime();
        if (currentTime < expirationTime) {
          return value;
        }
      }
      const token = await this.getToken();

      // You can add in some validation to check if the token is available before proceeding
      // You can also refresh the token if it's expired before proceeding
      const getIdResult = await this.cognitoidentity.getId({
        // Get the identityPoolId from config
        IdentityPoolId: environment.cognitoIdp,
        Logins: { [this.getIdentityProviderName()]: token }
      });

      const cognitoCredentialsResult =
        await this.cognitoidentity.getCredentialsForIdentity({
          IdentityId: getIdResult.IdentityId,
          Logins: {
            [this.getIdentityProviderName()]: token
          }
        });

      const credentials: CredentialsAndIdentityId = {
        credentials: {
          accessKeyId: cognitoCredentialsResult.Credentials?.AccessKeyId,
          secretAccessKey: cognitoCredentialsResult.Credentials?.SecretKey,
          sessionToken: cognitoCredentialsResult.Credentials?.SessionToken,
          expiration: cognitoCredentialsResult.Credentials?.Expiration
        },
        identityId: getIdResult.IdentityId
      };

      if (token) {
        await Cache.setItem('federatedInfo', credentials, {
          expires: jwtDecode<JwtPayload>(token).exp * 1000
        });
      }

      return credentials;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred getting cognito credentials'
      });
    }
  }

  // Implement this to clear any cached credentials and identityId. This can be called when signing out of the federation service.
  clearCredentialsAndIdentityId(): void {}

  getIdentityProviderName(): string {
    return this.amplifyIdp === 'xgs'
      ? environment.xgsIdp
      : environment.goAiguaIdp;
  }

  setAmplifyIdp(idp: string): void {
    this.amplifyIdp = idp;
  }

  async getToken(): Promise<string> {
    const source$ = this.oidcSecurityService.getAccessToken(this.amplifyIdp);

    return lastValueFrom(source$);
  }
}
