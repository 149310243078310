/* eslint-disable dot-notation */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { S2sAddLoginDialogComponent } from './s2s-add-login-dialog/s2s-add-login-dialog.component';
import { S2sLoginMgmtService } from './s2s-login-mgmt.service';
import { LoadingService } from '@gravity-angular/layout';
import { environment } from '@env/environment';
import { AlertsService } from '@gravity-angular/base';
import { ColorType } from '@gravity-angular/models';
import { MatPaginator } from '@angular/material/paginator';
import { UmService } from 'app/auth/um-service/um.service';
import { ExdlRoles, UDLUserData } from '@common/models/user-management.model';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

@Component({
  selector: 'app-s2s-connection-mgmt',
  templateUrl: './s2s-connection-mgmt.component.html',
  styleUrls: ['./s2s-connection-mgmt.component.scss']
})
export class S2sConnectionMgmtComponent implements OnInit, OnChanges {
  @Input() customerId: string;
  @Input() cluster: string;
  @Output() loginsChangeEvent = new EventEmitter<number>();

  @ViewChild('loginsTable') loginsSort: MatSort;
  @ViewChild('loginsHistoryTable') loginsHistorySort: MatSort;
  @ViewChild('loginsHistoryPaginator') loginsHistoryPaginator: MatPaginator;

  canEdit: boolean;
  disabled: boolean;
  canAddLogin: boolean;
  currentLogins = 0;
  maxLogins = 0;
  logins: any = [];
  loginsDatasource: MatTableDataSource<any>;
  loginsHistory: any = [];
  loginsHistoryDatasource: MatTableDataSource<any>;

  loginsDisplayedColumns: string[] = [
    'username',
    'description',
    'created_on',
    'created_by',
    'last_modified_on',
    'last_modified_by',
    'resetPassword',
    'deleteLogin'
  ];

  loginsTableDef: any[] = [
    {
      key: 'username',
      header: 'Username'
    },
    {
      key: 'description',
      header: 'Description'
    },
    {
      key: 'created_on',
      header: 'Created On (UTC)'
    },
    {
      key: 'created_by',
      header: 'Created By'
    },
    {
      key: 'last_modified_on',
      header: 'Last Modified On (UTC)'
    },
    {
      key: 'last_modified_by',
      header: 'Last Modified By'
    }
  ];

  loginsHistoryDisplayedColumns: string[] = [
    'approximate_creation_date_time_iso8601',
    'username',
    'modifications',
    'modified_by'
  ];

  loginsHistoryTableDef: any[] = [
    {
      key: 'approximate_creation_date_time_iso8601',
      header: 'Date (UTC)'
    },
    {
      key: 'username',
      header: 'Username'
    },
    {
      key: 'modifications',
      header: 'Action'
    },
    {
      key: 'modified_by',
      header: 'Modified By'
    }
  ];

  private readonly userData: UDLUserData;

  constructor(
    private readonly umService: UmService,
    private readonly s2sLoginMgmtService: S2sLoginMgmtService,
    private readonly loadingService: LoadingService,
    private readonly datadogService: DatadogService,
    private readonly alertsService: AlertsService,
    public dialog: MatDialog
  ) {
    this.userData = this.umService.getUserData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerId.currentValue) {
      this.refresh();
    }
  }

  async ngOnInit(): Promise<void> {
    this.maxLogins = environment.maxS2SLogins;
    this.canEdit = Object.keys(this.userData.xgsRoles).includes(
      ExdlRoles.SUPER_ADMIN
    );

    this.refresh();
  }

  addLogin(): void {
    const dialogRef = this.dialog.open(S2sAddLoginDialogComponent, {
      width: '50%',
      data: {
        usernames: this.logins.map(login => {
          return login.username;
        }),
        customerId: this.customerId
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.disabled = true;
        this.loadingService.showLoading(true);
        await this.s2sLoginMgmtService
          .addLogin(
            this.customerId,
            result.username,
            result.description,
            this.cluster
          )
          .then(async response => {
            this.confirmationDialog(
              `Password: ${response['Password']}`,
              'This password will only be displayed once and cannot be retrieved. If you forget the password, this login ' +
                'will need to be reset.',
              false
            );
          })
          .catch(e => {
            this.datadogService.errorTracking(e, {
              message: 'Error creating login'
            });
            this.showError('Error creating login', e);
          })
          .finally(() => {
            this.refresh();
          });
      }
    });
  }

  resetPassword(login: any): void {
    const dialogRef = this.confirmationDialog(
      'Are you sure?',
      'You are about to reset the password for this login. Any connections currently using this login will ' +
        'no longer work until updated with the new password.',
      true
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.disabled = true;
        this.loadingService.showLoading(true);
        await this.s2sLoginMgmtService
          .resetLogin(login, this.cluster)
          .then(response => {
            this.confirmationDialog(
              `Password: ${response['Password']}`,
              'This password will only be displayed once and cannot be retrieved. If you forget the password, this login ' +
                'will need to be reset.',
              false
            );
          })
          .catch(e => {
            this.datadogService.errorTracking(e, {
              message: 'Error resetting password'
            });
            this.showError('Error resetting password', e);
          })
          .finally(() => {
            this.refresh();
          });
      }
    });
  }

  deleteLogin(login: any): void {
    const dialogRef = this.confirmationDialog(
      'Are you sure?',
      'Once deleted, this login cannot be recovered. Any connections currently using this login will no longer work.',
      true
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.disabled = true;
        this.loadingService.showLoading(true);
        await this.s2sLoginMgmtService
          .deleteLogin(login, this.cluster)
          .catch(e => {
            this.datadogService.errorTracking(e, {
              message: 'Error deleting login'
            });
            this.showError('Error deleting login', e);
          })
          .finally(() => {
            this.refresh();
          });
      }
    });
  }

  private refresh(): void {
    this.refreshLogins();
    this.refreshLoginHistory();
  }

  private refreshLogins(): void {
    this.disabled = true;
    this.loadingService.showLoading(true);
    this.s2sLoginMgmtService
      .getLogins(this.customerId)
      .then(response => {
        this.logins = response;
      })
      .catch(e => {
        this.datadogService.errorTracking(e, {
          message: 'Error loading logins'
        });
        this.showError('Error loading logins', e);
      })
      .finally(() => {
        this.loginsDatasource = new MatTableDataSource(this.logins);
        this.loginsDatasource.sort = this.loginsSort;
        this.currentLogins = this.logins.length;
        this.canAddLogin = this.logins.length < this.maxLogins;
        this.loadingService.showLoading(false);
        this.disabled = false;
        this.loginsChangeEvent.emit(this.currentLogins);
      });
  }

  private refreshLoginHistory(): void {
    this.loadingService.showLoading(true);
    this.s2sLoginMgmtService
      .getLoginsHistory(this.customerId)
      .then(response => {
        this.loginsHistory = response;
      })
      .catch(e => {
        this.datadogService.errorTracking(e, {
          message: 'Error loading login history'
        });
        this.showError('Error loading login history', e);
      })
      .finally(() => {
        this.loginsHistoryDatasource = new MatTableDataSource(
          this.loginsHistory
        );
        this.loginsHistoryDatasource.sort = this.loginsHistorySort;
        this.loginsHistoryDatasource.paginator = this.loginsHistoryPaginator;
        this.loadingService.showLoading(false);
      });
  }

  private confirmationDialog(
    title: string,
    description: string,
    showCancelButton: boolean
  ): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: {
        title,
        description,
        showCancelButton
      }
    });
  }

  private showError(message: string, e): void {
    this.alertsService.addAlert({
      type: ColorType.warn,
      title: message,
      message: 'If problem persists, contact your administrator.',
      dismissable: true
    });
  }
}
