/* eslint-disable dot-notation */
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from '@common/notifications/notifications.service';
import { RoleAuditTrailService } from '../role-audit-trail-service/role-audit-trail.service';
import { UserAuditService } from '../user-audit-trail-service/user-audit.service';
import {
  AbbrevateTimeZonePipe,
  RoleAuditImagePipe,
  TimezoneConversionPipe,
  UserAuditImagePipe
} from 'app/shared/pipes/shared.pipe';
import { Subscription } from 'rxjs';
import { UDLUserData, XGSCustomer } from '@common/models/user-management.model';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';
import { UserFunctionsService } from 'app/user-management/user/user-functions-service/user-functions.service';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

@Component({
  selector: 'app-audit-trail-dialog',
  templateUrl: './audit-trail-dialog.component.html',
  styleUrls: ['./audit-trail-dialog.component.scss'],
  providers: [
    TimezoneConversionPipe,
    UserAuditImagePipe,
    RoleAuditImagePipe,
    AbbrevateTimeZonePipe
  ]
})
export class AuditTrailDialogComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('userPaginator') userPaginator: MatPaginator;
  @ViewChild('rolePaginator') rolePaginator: MatPaginator;
  @ViewChild('roleSort') roleSort: MatSort;
  @ViewChild('userSort') userSort: MatSort;

  okText = $localize`:@@audit-trail_audit-trail-dialog_close:Close`;

  content: string;
  userData: UDLUserData;
  dataLoaded = false;

  roleList: Object[];
  userList: Object[];

  roleLength = 0;
  userLength = 0;

  roleDataSource = new MatTableDataSource([]);
  userDataSource = new MatTableDataSource([]);

  roleColumnsCluster: string[] = [
    'customer_id',
    'changed_by',
    'role_altered',
    'role_action',
    'initial_value',
    'altered_value',
    'alteration_timestamp'
  ];

  userColumnsCluster: string[] = [
    'customer_id',
    'changed_by',
    'user_altered',
    'user_action',
    'initial_value',
    'altered_value',
    'alteration_timestamp'
  ];

  roleTableDefCluster: Object[] = [
    {
      key: 'changed_by',
      header: 'Altered By'
    },
    {
      key: 'role_altered',
      header: 'Role Altered'
    },
    {
      key: 'role_action',
      header: 'Action'
    },
    {
      key: 'initial_value',
      header: 'Initial Value'
    },
    {
      key: 'altered_value',
      header: 'New Value'
    },
    {
      key: 'alteration_timestamp',
      header: 'Time (UTC)'
    },
    {
      key: 'customer_id',
      header: 'Customer'
    }
  ];

  userTableDefCluster: Object[] = [
    {
      key: 'changed_by',
      header: 'Altered By'
    },
    {
      key: 'user_altered',
      header: 'User Altered'
    },
    {
      key: 'user_action',
      header: 'Action'
    },
    {
      key: 'initial_value',
      header: 'Initial Value'
    },
    {
      key: 'altered_value',
      header: 'New Value'
    },
    {
      key: 'alteration_timestamp',
      header: 'Time (UTC)'
    },
    {
      key: 'customer_id',
      header: 'Customer'
    }
  ];

  xgsCustomer: XGSCustomer;
  private readonly subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<AuditTrailDialogComponent>,
    private readonly roleAuditTrailService: RoleAuditTrailService,
    private readonly userAuditService: UserAuditService,
    private readonly customerService: CustomerService,
    private readonly datadogService: DatadogService,
    private readonly notificationService: NotificationsService,
    private readonly userFunctionsService: UserFunctionsService,
    @Inject(MAT_DIALOG_DATA) public data: Object
  ) {
    this.subscription = this.customerService.currentCustomer.subscribe(
      utilityValue => {
        this.xgsCustomer = utilityValue.customer;
      }
    );
  }

  async ngOnInit(): Promise<void> {
    this.content = this.data['content'];
    this.userData = this.data['userViewing'];
    await (this.content === 'role'
      ? this.retrieveRoleAuditTrail()
      : this.retrieveUserAuditTrail());
    this.dataLoaded = true;
  }

  ngAfterViewInit(): void {
    // Custom sorting function for last login column
    this.userDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'alteration_timestamp':
          return this.userAuditService.auditTimeSortFunction(item);
        default:
          return item[property];
      }
    };
    this.userDataSource.sort = this.userSort;

    // Custom sorting function for alteration timestamp column
    this.roleDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'alteration_timestamp':
          return this.userAuditService.auditTimeSortFunction(item);
        default:
          return item[property];
      }
    };
    this.roleDataSource.sort = this.roleSort;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /* Called to retrieve audit trail for a given role */
  async retrieveRoleAuditTrail(): Promise<void> {
    const request = {
      requestingUser: this.userData.xgsRoles,
      roleRequesting: this.data['role']
    };

    try {
      const response = await this.roleAuditTrailService.getRoleTrail(request);
      const tableData = await this.notificationService.csvToJson(
        response['s3Key'],
        'from_ddb/udl_role_audit_trail'
      );
      if (Array.isArray(tableData) && Object.keys(tableData[0])[0] !== '') {
        this.roleList = tableData;
        this.roleLength = this.roleList.length;
        this.roleList.sort((a, b) => {
          return a['alteration_timestamp'] < b['alteration_timestamp'] ? 1 : -1;
        });
        this.roleDataSource.data = JSON.parse(JSON.stringify(this.roleList));
        this.roleDataSource.paginator = this.rolePaginator;
        this.roleDataSource.sort = this.roleSort;
      } else {
        this.roleDataSource.data = [];
        this.roleDataSource.paginator = this.rolePaginator;
        this.roleDataSource.sort = this.roleSort;
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error getting role audit trail'
      });
      this.roleDataSource.data = [];
      this.roleDataSource.paginator = this.rolePaginator;
      this.roleDataSource.sort = this.roleSort;
    }
  }

  /* Called to retrieve audit trail for a given user */
  async retrieveUserAuditTrail(): Promise<void> {
    const request = {
      requestingUser: this.userData.xgsRoles,
      userRequesting: this.data['user']['email']
    };

    try {
      const response = await this.userAuditService.getUserTrail(request);
      const tableData = await this.notificationService.csvToJson(
        response['s3Key'],
        'from_ddb/udl_user_audit_trail'
      );

      if (Array.isArray(tableData) && Object.keys(tableData[0])[0] !== '') {
        this.userList = tableData;
        this.userLength = this.userList.length;
        this.userList.sort((a, b) => {
          return a['alteration_timestamp'] < b['alteration_timestamp'] ? 1 : -1;
        });
        this.userDataSource.data = JSON.parse(JSON.stringify(this.userList));
        this.userDataSource.paginator = this.userPaginator;
        this.userDataSource.sort = this.userSort;
      } else {
        this.userLength = 0;
        this.userDataSource.data = [];
        this.userDataSource.paginator = this.userPaginator;
        this.userDataSource.sort = this.userSort;
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error getting user audit trail'
      });
      this.userLength = 0;
      this.userDataSource.data = [];
      this.userDataSource.paginator = this.userPaginator;
      this.userDataSource.sort = this.userSort;
    }
  }

  /**
   * Gets the page size for the user/role audit table
   * @returns page size for the user/role audit table
   */
  getPageSize(): number {
    const preferences = this.userFunctionsService.getPreferences(this.content);

    return preferences?.pageSize ? preferences.pageSize : 5;
  }

  close(): void {
    this.dialogRef.close();
  }
}
