/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomerService } from '../../shared/services/customer-service/customer.service';

import { NotificationsService } from '@common/notifications/notifications.service';
import { DataTransferService } from '../service/data-transfer.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertsService } from '@gravity-angular/base';
import { ColorType } from '@gravity-angular/models';
import { AmplifyService } from '../../aws/amplify/amplify.service';
import { CustomerIdChangePopupComponent } from '../customer-id-change-popup/customer-id-change-popup.component';

import { startWith } from 'rxjs/internal/operators/startWith';
import { map, take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

import { PopupComponent } from '../popup/popup.component';
import { UmService } from 'app/auth/um-service/um.service';

@Component({
  selector: 'app-cluster-analysis-homepage',
  templateUrl: './cluster-analysis-homepage.component.html',
  styleUrls: ['./cluster-analysis-homepage.component.scss'],
  providers: [DatePipe]
})
export class ClusterAnalysisHomepageComponent implements OnInit, OnDestroy {
  customer_id = '';
  clusteringfilters: FormGroup;
  currentYear;
  currentMonth;
  currentDay;
  maxEndDateAllowed;
  startDateDynamicValue;
  endDateDynamicValue;
  showSidebar = true;
  hideDivider = false;
  applyFilterButtonDisabled = false;
  icon = 'keyboard_arrow_right';

  cluster_analysis_selected_option_from_popup: string;
  cluster_analysis_value_options: any;
  value_from_popup: string[] = [];

  filterValues;
  meterSizeFilterValues;
  yAxisFilterValues;
  UOMvalue;
  filterError = false;
  filterErrorMessage;
  firstTimeInvocation = true;
  meterSizeFilterDisabler = false;
  no_of_clusters_range = Array.from(Array(100), (_, index) => {
    return index + 1;
  });

  filteredOptions: Observable<string[]>;
  subscription: Subscription;
  dateFormat = 'MM/dd/yyyy';
  private userData;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly customerService: CustomerService,
    private readonly notificationsService: NotificationsService,
    private readonly dataTransferService: DataTransferService,
    private readonly formBuilder: FormBuilder,
    private readonly alertsService: AlertsService,
    private readonly amplifyService: AmplifyService,
    private readonly umService: UmService,
    private readonly datePipe: DatePipe
  ) {
    const value_from_popup = this.dataTransferService.getDatafromPopUp();
    if (value_from_popup[0] === 'URLRefresh') {
      const dialogRef = this.dialog.open(PopupComponent, {
        width: '40%',
        height: '50%',
        maxHeight: '70%',
        maxWidth: '60%',
        disableClose: true,
        autoFocus: false
      });
      this.subscription = dialogRef
        .afterClosed()
        .subscribe(value_from_popup => {
          this.value_from_popup = this.dataTransferService.getDatafromPopUp();
          this.cluster_analysis_selected_option_from_popup =
            this.value_from_popup[0];
          this.cluster_analysis_value_options = this.value_from_popup[1];

          this.subscription = this.customerService.currentCustomer
            .pipe(take(1))
            .subscribe(utilityValue => {
              this.dataTransferService.resetOnFirstTimeInvocation(
                utilityValue.customer.customerId.toLowerCase()
              );
            });
        });
    } else {
      this.value_from_popup = this.dataTransferService.getDatafromPopUp();
    }
  }

  ngOnInit(): void {
    this.cluster_analysis_selected_option_from_popup = this.value_from_popup[0];
    this.cluster_analysis_value_options = this.value_from_popup[1];

    // Calling method to get default dates in filter
    const defaultDatesObj = this.getDatePickerDefaultValues();

    // creating form and setting default values
    this.clusteringfilters = this.formBuilder.group({
      meterSize: [{ disabled: this.meterSizeFilterDisabler }],
      yAxisOptions: [],
      UOM: [],
      startDate: [defaultDatesObj.defaultStartDate],
      endDate: [defaultDatesObj.defaultEndDate],
      clusteringConstant: [this.no_of_clusters_range[3]]
    });
    this.onChanges();

    // Code which listens for the chnages in Customer ID dropdown
    this.subscription = this.customerService.currentCustomer.subscribe(
      utilityValue => {
        // We need to have this firstTimeInvocation logic or else the Popup of Customer Change
        // Alert will come even at page load as the value in the dropdown updates after the
        // page is rendered.
        const curretPage = this.router.url;

        if (this.firstTimeInvocation) {
          this.updateCustomerID(utilityValue.customer.customerId.toLowerCase());
          // Setting value for variable containing value for First time invocation
          this.firstTimeInvocation = false;
          this.dataTransferService.resetOnFirstTimeInvocation(
            utilityValue.customer.customerId.toLowerCase()
          );
        } else if (
          this.firstTimeInvocation === false &&
          this.value_from_popup[0] !== 'URLRefresh' &&
          curretPage === '/ClusterAnalysis/clusterAnalysisHomePage' &&
          this.value_from_popup.length !== 0
        ) {
          const dialogRef = this.dialog.open(CustomerIdChangePopupComponent, {
            width: '40%',
            height: '50%',
            maxHeight: '70%',
            maxWidth: '60%',
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === 0) {
              this.updateCustomerID(
                utilityValue.customer.customerId.toLowerCase()
              );
              this.dataTransferService.resetOnCustomerChange(
                utilityValue.customer.customerId.toLowerCase()
              );
            }
          });
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async callFilterValuesAPI(method, apiName, path, params): Promise<object> {
    return this.amplifyService.callAPI(method, apiName, path, params);
  }

  async getFilterValues(customerID) {
    this.notificationsService.setLoadingState(true);
    const params = {
      queryParams: {}
    };
    params.queryParams['customer_id'] = customerID;
    this.filterValues = await this.callFilterValuesAPI(
      'get',
      'API_CLUSTERING_ANALYSIS',
      'getfiltervalues',
      params
    );

    if (this.filterValues.error) {
      this.filterError = true;
      this.filterErrorMessage = this.filterValues.error;
      this.meterSizeFilterValues = null;
      this.yAxisFilterValues = null;
      this.UOMvalue = null;
      this.notificationsService.setLoadingState(false);
    } else {
      this.filterError = false;
      this.clusteringfilters.enable();
      if (this.filterValues.meterSizeQuery[0] == null) {
        this.meterSizeFilterDisabler = true;
      } else {
        this.meterSizeFilterDisabler = false;
        this.meterSizeFilterValues = this.filterValues.meterSizeQuery;
      }
      this.yAxisFilterValues = this.filterValues.metaDataFilterQuery;
      this.UOMvalue = this.filterValues.UOMFilterQuery;
      this.notificationsService.setLoadingState(false);
    }
  }

  async startClusteringAPI(method, apiName, path, params) {
    return this.amplifyService.callAPI(method, apiName, path, params);
  }

  updateCustomerID(customer: string) {
    this.notificationsService.setLoadingState(true);
    if (customer.length === 0) {
      this.notificationsService.setLoadingState(false);

      return;
    } else {
      this.customer_id = customer;
      this.clusteringfilters.disable();
      this.getFilterValues(customer);
      this.notificationsService.setLoadingState(false);
    }
  }

  getDatePickerDefaultValues() {
    const date = new Date();
    const currentYear = date.getUTCFullYear();
    this.currentMonth = date.getUTCMonth();
    this.currentDay = date.getUTCDate();

    if (this.currentMonth < 10) {
      this.currentMonth = `0${this.currentMonth}`;
    }
    if (this.currentDay < 10) {
      this.currentMonth = `0${this.currentMonth}`;
    }

    this.maxEndDateAllowed = new Date(
      currentYear,
      this.currentMonth,
      this.currentDay - 1
    );

    return {
      defaultStartDate: new Date(
        currentYear,
        this.currentMonth,
        this.currentDay - 8
      ),
      defaultEndDate: new Date(
        currentYear,
        this.currentMonth,
        this.currentDay - 1
      )
    };
  }

  toggleFilter() {
    // Code for collapsing the filter sidebar
    this.showSidebar = !this.showSidebar;
    this.icon = this.showSidebar
      ? 'keyboard_arrow_right'
      : 'keyboard_arrow_left';
  }

  async applyFilters() {
    this.notificationsService.setLoadingState(true);
    const startDateValue = new Date(this.clusteringfilters.value.startDate);
    const endDateValue = new Date(this.clusteringfilters.value.endDate);

    // Date Field Validation -if start date is After End Date
    if (startDateValue.getTime() > endDateValue.getTime()) {
      this.notificationsService.setLoadingState(false);
      this.notificationsService.createAlert(
        ColorType.danger,
        'Date Error',
        'End Date Precceds Start Date',
        true
      );
      this.clusteringfilters.patchValue({
        startDate: ''
      });
    }

    // Date Field Validation -if start date and End Date Difference is more than 30 days
    else if (endDateValue.getTime() - startDateValue.getTime() > 2505600000) {
      this.notificationsService.setLoadingState(false);
      this.notificationsService.createAlert(
        ColorType.danger,
        'Date Error',
        'Clustering for More than 30 days Selected',
        true
      );
      this.clusteringfilters.patchValue({
        startDate: '',
        endDate: ''
      });
    } else {
      if (
        this.clusteringfilters.value.meterSize === '' ||
        !this.clusteringfilters.value.meterSize ||
        this.clusteringfilters.value.yAxisOptions === '' ||
        !this.clusteringfilters.value.yAxisOptions ||
        this.clusteringfilters.value.UOM === '' ||
        !this.clusteringfilters.value.UOM
      ) {
        this.notificationsService.setLoadingState(false);
        this.notificationsService.createAlert(
          ColorType.danger,
          'Filter Error',
          'Filter Values are missing/empty',
          true
        );
      } else if (
        !this.yAxisFilterValues.includes(
          this.clusteringfilters.value.yAxisOptions
        )
      ) {
        this.notificationsService.setLoadingState(false);
        this.notificationsService.createAlert(
          ColorType.danger,
          'Filter Error',
          'Incorrect Value in selected for Y Axis Option',
          true
        );
      } else {
        // Converting from DateTime to string
        const start_date_string = this.datePipe.transform(
          startDateValue,
          this.dateFormat
        );
        const end_date_string = this.datePipe.transform(
          endDateValue,
          this.dateFormat
        );

        if (
          start_date_string === 'Invalid Date' ||
          end_date_string === 'Invalid Date'
        ) {
          this.notificationsService.setLoadingState(false);
          this.notificationsService.createAlert(
            ColorType.danger,
            'Date Error',
            'Invalid Date',
            true
          );
        } else {
          // Getting User Email ID
          this.userData = this.umService.getUserData();

          // Creating parameter obj and adding all the necessary value to pass it to the API
          const params = {
            queryParams: {}
          };
          params.queryParams['meterSize'] =
            this.clusteringfilters.value.meterSize;
          params.queryParams['yAxisOptions'] =
            this.clusteringfilters.value.yAxisOptions;
          params.queryParams['UOM'] = this.clusteringfilters.value.UOM;
          params.queryParams['startDate'] = start_date_string;
          params.queryParams['endDate'] = end_date_string;
          params.queryParams['clusteringConstant'] =
            this.clusteringfilters.value.clusteringConstant;
          params.queryParams['customer_id'] = this.customer_id;
          params.queryParams['user_email'] = this.userData.email;

          // Calling API to start creating cluster
          const clustering_result = await this.startClusteringAPI(
            'get',
            'API_CLUSTERING_ANALYSIS',
            'startclustering',
            params
          );

          if (clustering_result['error']) {
            this.notificationsService.setLoadingState(false);
            this.notificationsService.createAlert(
              ColorType.danger,
              'Faliure',
              `Clustering falied due to ${clustering_result['error']}`,
              true
            );
          } else if (clustering_result['model_name'] === 'undefined') {
            this.notificationsService.setLoadingState(false);
            this.notificationsService.createAlert(
              ColorType.danger,
              'Faliure',
              'Clustering falied with model name as undefined',
              true
            );
          } else {
            this.notificationsService.setLoadingState(false);
            this.notificationsService.createAlert(
              ColorType.success,
              'Success',
              `Clustering initiated with the given parameters and model name: 
              ${clustering_result['model_name']}, Please check after 1 hour`,
              true
            );
          }
        }
      }
    }
  }

  onSubmit_og() {
    const startDateValue = new Date(this.clusteringfilters.value.startDate);
    const endDateValue = new Date(this.clusteringfilters.value.endDate);

    // Date Field Validation -if start date is After End Date
    if (startDateValue.getTime() > endDateValue.getTime()) {
      this.alertsService.addAlert({
        type: ColorType.danger,
        title: 'Date Error',
        message: 'End Date Precceds Start Date',
        dismissable: true
      });
      this.clusteringfilters.patchValue({
        startDate: ''
      });
    }
    // Date Field Validation -if start date and End Date Difference is more than 30 days
    if (endDateValue.getTime() - startDateValue.getTime() > 2505600000) {
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: 'Date Error',
        message: 'Clustering for More than 30 days Selected',
        dismissable: true
      });
      this.clusteringfilters.patchValue({
        startDate: '',
        endDate: ''
      });
    }

    this.alertsService.addAlert({
      type: ColorType.success,
      title: 'Clustering Intiated',
      message:
        'Clustering initiated with the given parameters, You will recive an email once clustering is completed',
      dismissable: true
    });
  }

  applyFilters_og() {
    // Converting from DateTime to string
    const startDateValue = new Date(this.clusteringfilters.value.startDate);
    const endDateValue = new Date(this.clusteringfilters.value.endDate);
    const start_date_string = startDateValue.toLocaleDateString();
    const end_date_string = endDateValue.toLocaleDateString();

    // Getting User Email ID
    this.userData = this.umService.getUserData();

    // Creating parameter obj and adding all the necessary value to pass it to the API
    const params = {
      queryParams: {}
    };
    params.queryParams['meterSize'] = this.clusteringfilters.value.meterSize;
    params.queryParams['yAxisOptions'] =
      this.clusteringfilters.value.yAxisOptions;
    params.queryParams['UOM'] = this.clusteringfilters.value.UOM;
    params.queryParams['startDate'] = start_date_string;
    params.queryParams['endDate'] = end_date_string;
    params.queryParams['clusteringConstant'] =
      this.clusteringfilters.value.clusteringConstant;
    params.queryParams['customer_id'] = this.customer_id;
    params.queryParams['user_email'] = this.userData.email;
    // Calling API to start creating cluster
    this.startClusteringAPI(
      'get',
      'API_CLUSTERING_ANALYSIS',
      'startclustering',
      params
    );
  }

  clearFilterResults() {
    this.clusteringfilters.reset();
  }

  onChanges() {
    this.filteredOptions = this.clusteringfilters
      .get('yAxisOptions')
      .valueChanges.pipe(
        startWith(this.yAxisFilterValues),
        map(val => {
          return this.filter(val);
        })
      );
  }

  filter(val: any): any[] {
    if (val === undefined || val === null) {
    } else if (val === '') {
      return this.yAxisFilterValues;
    } else {
      return this.yAxisFilterValues.filter(option => {
        return Array.isArray(val)
          ? option.toLowerCase().match(val[0].toLowerCase())
          : option.toLowerCase().match(val.toLowerCase());
      });
    }
  }
}
