import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '@gravity-angular/base';

@Component({
  selector: 'app-s2s-add-login-dialog',
  templateUrl: './s2s-add-login-dialog.component.html',
  styleUrls: ['./s2s-add-login-dialog.component.scss']
})
export class S2sAddLoginDialogComponent implements OnInit {
  form: FormGroup;
  existingUsernames: string[];
  customerId: string;
  currentSystem: string;

  readonly availableSystems: { label: string; value: string }[];

  constructor(
    public dialogRef: MatDialogRef<S2sAddLoginDialogComponent>,
    private readonly alertsService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.availableSystems = [
      {
        label: 'Power BI Report Server',
        value: 'power_bi_rs'
      },
      {
        label: 'Power BI Azure',
        value: 'power_bi_azure'
      },
      {
        label: 'SAP Crystal Server',
        value: 'crystal_server'
      },
      {
        label: 'Other',
        value: 'other_system'
      }
    ];
  }

  ngOnInit(): void {
    this.existingUsernames = this.data.usernames;
    this.customerId = this.data.customerId;
    this.setForm();
  }

  setForm(): void {
    this.form = new FormGroup({
      system: new FormControl(this.data.system, [Validators.required]),
      description: new FormControl(this.data.description, [
        Validators.pattern(
          '^[a-zA-z\\s\'-][^!@#$%^&*().,<>?_|{}+=`~"0-9]{0,250}$'
        )
      ])
    });
  }

  addLogin(): void {
    if (!this.form.valid) {
      return;
    }

    const buffer = new Uint16Array(1);
    let username = '';

    // Generates the username with format <custId>_<system>_<randomNumber>
    // In the event the username already exists (very unlikely) regenerate the random number until it is unique
    do {
      crypto.getRandomValues(buffer);
      const randomNumber = buffer[0];
      username = `${this.customerId.toLowerCase()}_${
        this.currentSystem
      }_${randomNumber}`;
    } while (this.existingUsernames.includes(username));

    this.dialogRef.close({
      username,
      description: this.form.value.description
    });
  }

  systemSelected(system: string): void {
    this.currentSystem = system;

    // If other_system selected, force required validation
    this.form.controls.description.setValidators(
      system === 'other_system' ? [Validators.required] : []
    );
    this.form.controls.description.addValidators(
      Validators.pattern(
        '^[a-zA-z\\s\'-][^!@#$%^&*().,<>?_|{}+=`~"0-9]{0,250}$'
      )
    );
    this.form.controls.description.updateValueAndValidity();
  }
}
