import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { AuthGuard, SyncGuardHelper } from 'app/auth/auth-guards/auth.guard';
import { XgsGuardGuard } from 'app/auth/auth-guards/xgs-guard.guard';
import { ClusterAnalysisHomepageComponent } from './cluster-analysis-homepage/cluster-analysis-homepage.component';
import { BlankHomepageComponent } from './blank-homepage/blank-homepage.component';
import { ExdlPermissions } from '@common/models/user-management.model';

const routes: Routes = [
  {
    path: 'clusterAnalysisHomePage',
    component: ClusterAnalysisHomepageComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([SyncGuardHelper]),
    data: {
      syncGuards: [XgsGuardGuard, AuthGuard],
      permission: ExdlPermissions.CLUSTER_ANALYSIS
    }
  },
  {
    path: 'cluster_analysis_type_selection',
    component: BlankHomepageComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([SyncGuardHelper]),
    data: {
      syncGuards: [XgsGuardGuard, AuthGuard],
      permission: ExdlPermissions.CLUSTER_ANALYSIS
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClusterAnanlysisRoutingModule {}
