<div>
    <h1 mat-dialog-title>Customer Change Alert!!!</h1>
    <form>
        <div mat-dialog-content>
            <p>Please confirm the customer Change. This would reset the filters and corresponing clusters (if any). </p>
        </div>
        <div mat-dialog-actions>
            <button mat-button [mat-dialog-close]="0"  mat-dialog-close class="Confirm">Confirm</button>
            <button mat-button [mat-dialog-close]="1" mat-dialog-close cdkFocusInitial class="Abort">Abort</button>
        </div>

    </form>
</div>