<div>
    <h1 mat-dialog-title>Select type of Cluster Analysis to perform</h1>
    <form (ngSubmit)="onsubmit()">
        <div mat-dialog-content>
            <mat-select mat-dialog-content placeholder="Select" [(value)]="defaulltselectedoption">
                <mat-option *ngFor="let task of clusteringoptions" [value]="task">{{task}}</mat-option>
            </mat-select>
        </div>
        <div mat-dialog-actions>
            <br>
            <div style="margin: 0 auto;">
                <button type="submit" class="perform-analysis-button" mat-dialog-close>Perform Analysis</button>
            </div>
        </div>

    </form>
</div>