/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AmplifyService } from '../../aws/amplify/amplify.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTransferService } from '../service/data-transfer.service';
import { NotificationsService } from '@common/notifications/notifications.service';
import { Subscription } from 'rxjs';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';

@Component({
  selector: 'app-old-models',
  templateUrl: './old-models.component.html',
  styleUrls: ['./old-models.component.scss']
})
export class OldModelsComponent implements OnInit, OnDestroy {
  retriveDataButtonDisabled = true;
  OldModelsDropdownDiabled = true;
  noModelsErrorMessageFlag = false;
  modelIsReadyFlag = true;
  modelISReadyMessage;
  readyModelsObj = { READY: [] };
  customer_id: string;
  old_models_list: any[];
  noModelsErrorMessage: string;
  OldModels: FormGroup;
  subscription: Subscription;
  private userData;
  private userEmail;

  constructor(
    private readonly customerService: CustomerService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly amplifyService: AmplifyService,
    private readonly formBuilder: FormBuilder,
    private readonly dataTransferService: DataTransferService,
    private readonly notificationsService: NotificationsService
  ) {
    this.oidcSecurityService.getUserData().subscribe(data => {
      this.userData = data;
      this.userEmail = data.email;
    });
  }

  async ngOnInit(): Promise<void> {
    this.OldModels = this.formBuilder.group({
      oldModelsList: [{ disabled: this.OldModelsDropdownDiabled }]
    });

    this.subscription =
      this.dataTransferService.firstTimeInvocationListner.subscribe(
        customerName => {
          if (customerName.length === 0) {
            return;
          } else {
            this.customer_id = customerName;
            this.fetchOldModels();
          }
        }
      );

    this.subscription =
      this.dataTransferService.customerChangeEventListner.subscribe(
        customerName => {
          if (customerName.length === 0) {
            return;
          } else {
            this.customer_id = customerName;
            this.fetchOldModels();
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async fetchOldModels() {
    this.notificationsService.setLoadingState(true);
    const params = {
      queryParams: {}
    };
    params.queryParams['emailID'] = this.userEmail;
    params.queryParams['customerID'] = this.customer_id;
    params.queryParams['task_name'] = 'fetchOldModelsList';

    const fetchOldModelsQueryResult = await this.amplifyService.callAPI(
      'get',
      'API_CLUSTERING_ANALYSIS',
      'fetcholdmodels',
      params
    );

    if (fetchOldModelsQueryResult['error']) {
      this.noModelsErrorMessage = `Error: ${fetchOldModelsQueryResult['error']}`;
      this.noModelsErrorMessageFlag = true;
      this.OldModelsDropdownDiabled = true;
      this.modelIsReadyFlag = true;
      this.old_models_list = [];
      this.notificationsService.setLoadingState(false);
    } else if (fetchOldModelsQueryResult['fetchOldModelsList'].length > 0) {
      this.OldModelsDropdownDiabled = false;
      this.noModelsErrorMessageFlag = false;
      this.modelIsReadyFlag = false;
      this.old_models_list = fetchOldModelsQueryResult['fetchOldModelsList'];
      this.notificationsService.setLoadingState(false);
    } else {
      this.noModelsErrorMessage = 'No Existing Models Found';
      this.noModelsErrorMessageFlag = true;
      this.OldModelsDropdownDiabled = true;
      this.modelIsReadyFlag = true;
      this.old_models_list = [];
      this.notificationsService.setLoadingState(false);
    }
  }

  async checkModelStatus() {
    this.notificationsService.setLoadingState(true);

    const model_id = this.OldModels.get('oldModelsList').value;
    if (model_id.length > 0) {
      this.noModelsErrorMessageFlag = false;
      const params = {
        queryParams: {}
      };
      params.queryParams['emailID'] = this.userEmail;
      params.queryParams['customerID'] = this.customer_id;
      params.queryParams['task_name'] = 'getModelStatus';
      params.queryParams['model_id'] = model_id;

      const getModelStatusQueryResult = await this.amplifyService.callAPI(
        'get',
        'API_CLUSTERING_ANALYSIS',
        'fetcholdmodels',
        params
      );

      if (getModelStatusQueryResult['error']) {
        this.noModelsErrorMessage = `Error: ${getModelStatusQueryResult['error']}`;
        this.noModelsErrorMessageFlag = true;

        this.notificationsService.setLoadingState(false);
      } else if (getModelStatusQueryResult['getModelStatus'].length > 0) {
        this.OldModelsDropdownDiabled = false;
        this.noModelsErrorMessageFlag = false;

        const model_status = getModelStatusQueryResult['getModelStatus'][0];
        if (model_status === 'READY') {
          this.readyModelsObj.READY.push(model_id);
          this.retriveDataButtonDisabled = false;
          this.modelIsReadyFlag = false;
          this.noModelsErrorMessageFlag = false;
          this.modelISReadyMessage = `Model Status: ${model_status}`;
          this.notificationsService.setLoadingState(false);
        } else if (model_status === 'TRAINING') {
          this.noModelsErrorMessage = 'Model is still getting trained';
          this.noModelsErrorMessageFlag = true;
          this.retriveDataButtonDisabled = true;
          this.modelIsReadyFlag = true;
          this.notificationsService.setLoadingState(false);
        } else if (model_status === 'FAILED') {
          this.noModelsErrorMessage = 'Model creation FAILED';
          this.noModelsErrorMessageFlag = true;
          this.retriveDataButtonDisabled = true;
          this.modelIsReadyFlag = true;
          this.notificationsService.setLoadingState(false);
        } else {
          this.noModelsErrorMessage = `Model Status: ${model_status}`;
          this.noModelsErrorMessageFlag = true;
          this.retriveDataButtonDisabled = true;
          this.modelIsReadyFlag = true;
          this.notificationsService.setLoadingState(false);
        }
      } else {
        this.noModelsErrorMessage = "Couldn't fetch Model Status";
        this.noModelsErrorMessageFlag = true;
        this.notificationsService.setLoadingState(false);
      }
    } else {
      this.noModelsErrorMessage = 'No Model is Selcted';
      this.noModelsErrorMessageFlag = true;
      this.notificationsService.setLoadingState(false);
    }
  }

  async retriveData() {
    this.notificationsService.setLoadingState(true);
    const model_id = this.OldModels.get('oldModelsList').value;
    if (model_id.length > 0) {
      this.noModelsErrorMessageFlag = false;
      const params = {
        queryParams: {}
      };

      params.queryParams['customerID'] = this.customer_id;
      params.queryParams['model_id'] = model_id;

      const plot_data = await this.amplifyService.callAPI(
        'get',
        'API_CLUSTERING_ANALYSIS',
        'retrivedata',
        params
      );

      this.dataTransferService.scatterplotDataEvent(plot_data);
      this.notificationsService.setLoadingState(false);
    } else {
      this.noModelsErrorMessage = 'No Model is Selcted';
      this.noModelsErrorMessageFlag = true;
      this.notificationsService.setLoadingState(false);
    }
  }

  modelChangeEvent() {
    const model_id = this.OldModels.get('oldModelsList').value;
    const readyModelsList = this.readyModelsObj.READY;

    if (readyModelsList.includes(model_id)) {
      this.retriveDataButtonDisabled = false;
      this.modelIsReadyFlag = false;
    } else {
      this.retriveDataButtonDisabled = true;
      this.modelIsReadyFlag = true;
    }
  }
}
