import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { InvalidLoginDialogComponent } from '../invalid-login-dialog.component';
import { UmService } from 'app/auth/um-service/um.service';
import { lastValueFrom } from 'rxjs';
import { SupersetService } from 'app/superset/superset-service/superset.service';
import { AmplifyService } from 'app/aws/amplify/amplify.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class InvalidLoginService {
  constructor(
    public dialog: MatDialog,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly supersetService: SupersetService,
    private readonly cookieService: CookieService,
    private readonly amplifyService: AmplifyService,
    private readonly umService: UmService
  ) {}

  /**
   * Opens a dialog for clock skew error, signs out the user, deletes all cookies, and clears local storage.
   *
   * @returns {Promise<void>} A promise that resolves when the operations are complete.
   */
  async clockOutOfSync(): Promise<void> {
    this.dialog.open(InvalidLoginDialogComponent, {
      width: '35%',
      height: 'auto',
      panelClass: 'login-error',
      backdropClass: 'login-error-backgorund',
      disableClose: true,
      data: {
        errorType: 'clock-skew'
      }
    });
    await this.amplifyService.awsSignOut();
    this.cookieService.deleteAll();
    localStorage.clear();
  }

  /**
   * Called if user logs in without any roles assigned, resulting in user being notified and logged out.
   *
   * @returns {Promise<void>} A promise that resolves when the user is logged out.
   */
  async tokenInvalid(): Promise<void> {
    const customParams = this.logoutHelper();
    const dialogRef = this.dialog.open(InvalidLoginDialogComponent, {
      width: '650px',
      height: '275px',
      panelClass: 'login-error',
      backdropClass: 'login-error-backgorund',
      disableClose: true,
      data: {
        errorType: 'invalid-token'
      }
    });
    this.supersetService.invalidateToken();
    await lastValueFrom(dialogRef.afterOpened());
    setTimeout(async () => {
      await this.amplifyService.awsSignOut();
      await lastValueFrom(
        this.oidcSecurityService.logoff(
          this.umService.userIdp || 'xgs',
          customParams
        )
      );
      dialogRef.close();
    }, 5000);
  }

  /**
   * Checks for id_token_hint=undefined and removes due to missing id_token
   * @returns AuthOptions used for logout
   */
  logoutHelper(): AuthOptions {
    const customParams: AuthOptions = {
      urlHandler: url => {
        const newUrl = url.replace('id_token_hint=undefined', '');
        window.location.href = newUrl;
        localStorage.removeItem(this.umService.userIdp || 'xgs');
      }
    };

    return customParams;
  }
}
