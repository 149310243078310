import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataTransferService } from '../service/data-transfer.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  selectedoption: any;
  clusteringoptions: string[] = [
    'Water Consumption by Meter Size',
    'Other Future TBD'
  ];

  defaulltselectedoption = this.clusteringoptions[0];

  constructor(
    private readonly dialog: MatDialog,
    private readonly Ref: MatDialogRef<PopupComponent>,
    private readonly dataTransferService: DataTransferService
  ) {}

  ngOnInit(): void {}

  onsubmit() {
    this.selectedoption = this.defaulltselectedoption;
    this.dataTransferService.sendDatafromPopUp([
      this.selectedoption,
      this.clusteringoptions
    ]);
  }
}
