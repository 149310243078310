/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { SummaryCount } from '@gravity-angular/components';
import { UsageService } from './usage-service/usage-service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '@env/environment';

// User Management
import { XgsUmService } from '../auth/xgs-service/xgs-um.service';
import moment, { Moment } from 'moment';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';
import { CustomTranslatorService } from 'app/shared/services/custom-translator/custom-translator.service';
import { NotificationsService } from '@common/notifications/notifications.service';

export interface UsageGraphDataElement {
  time: string;
  data: number;
}

const ELEMENT_DATA: UsageGraphDataElement[] = [];

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss']
})
export class UsageComponent implements OnInit {
  data_loaded = true;
  clickEventSubscription: Subscription;
  subscription: Subscription;

  summaryCounts: SummaryCount[];

  method = 'retrieve_usage_sum';

  customerPrefix = 'Select a Customer...';
  currentUser: Object;
  userIds: object = {};
  customers: string[] = [];
  onboardedCustomers: object = {};
  isCustomerOnboarded = false;
  customer: string;

  buttonText = $localize`:@@udl-usage_xg-button-submit:Submit`;
  buttonType = 'flat';
  buttonColor = 'primary';
  validate = true;

  labelTable: string;
  labelQueries: string;
  selectedStartDate: Moment;
  selectedEndDate: Moment;
  totalDataUsage = '0.0 GB';
  totalBytes: string;
  dataLevel = 'low';

  dailyUsageGraph = new MatTableDataSource<UsageGraphDataElement>(ELEMENT_DATA);

  form: FormGroup;
  form2: FormGroup;
  dateRangeControl = new FormControl();
  date = new Date();
  sDate = moment(new Date(this.date.getFullYear(), this.date.getMonth(), 1));
  endDate = new FormControl(moment(this.date));
  startDate = new FormControl(this.sDate);
  currentDate = new FormControl(moment(this.date));
  dataScanned = new FormControl('0.00');

  start_date = '2022-04-19';
  end_date = '2022-05-27';

  tabRoutes: { link: string; label: string }[];

  graph;

  constructor(
    private readonly fb: FormBuilder,
    private readonly usageService: UsageService,
    private readonly customerService: CustomerService,
    private readonly xgsUmService: XgsUmService,
    private readonly notificationsService: NotificationsService,
    public customTranslatorService: CustomTranslatorService
  ) {
    this.labelTable = this.customTranslatorService.translate(
      $localize`:@@udl-usage_mat-tab_storage:Table Storage`,
      'udl-usage_mat-tab_storage'
    );
    this.labelQueries = this.customTranslatorService.translate(
      $localize`:@@udl-usage_mat-tab_queries:SQL Queries`,
      'udl-usage_mat-tab_queries'
    );
    this.graph = {
      data: [],
      layout: {
        title: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage_data-day:Data Scanned By Day`,
          'udl-usage_usage_data-day'
        ),
        y: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage_Ybytes:Bytes (GB)`,
          'udl-usage_usage_Ybytes'
        ),
        x: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage_date:Date`,
          'udl-usage_usage_date'
        ),
        plot_bgcolor: '#F0F2F6',
        gridcolor: '#FFFFFF',
        xaxis: {
          gridcolor: '#FFFFFF',
          time: {
            tooltipFormat: this.dateFormat() // <- HERE
          },
          title: {
            text: this.customTranslatorService.translate(
              $localize`:@@udl-usage_usage_date:Date`,
              'udl-usage_usage_date'
            )
          }
        },
        yaxis: {
          title: {
            text: this.customTranslatorService.translate(
              $localize`:@@udl-usage_usage_Ybytes:Bytes (GB)`,
              'udl-usage_usage_Ybytes'
            )
          },
          gridcolor: '#FFFFFF'
        }
      }
    };
    this.form = fb.group({
      startDate: this.startDate,
      endDate: this.endDate,
      dataScanned: this.dataScanned
    });
    this.startDate.valueChanges.subscribe(v => {
      this.selectedStartDate = v;
    });
    this.endDate.valueChanges.subscribe(v => {
      this.selectedEndDate = v;
    });
    this.clickEventSubscription = this.usageService
      .getClickEvent()
      .subscribe(() => {
        this.validate = false;
        this.updateUsageSum();
      });
    this.subscription = this.customerService.currentCustomer.subscribe(
      utilityValue => {
        this.customer = utilityValue.customer.customerId.toLowerCase();
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const current_date = `${year}-${month}-${day}`;
        const start_date = `${year}-${month}-1`;

        if (this.customer) {
          this.usageService.retrieveUsageSum(
            start_date,
            current_date,
            this.customer,
            'retrieve_usage_sum'
          );
        }
      }
    );
  }

  dateFormat(): string {
    if (this.customTranslatorService.userLocale === 'en_US') {
      return 'MM/DD/YYYY';
    }

    return 'DD/MM/YYYY';
  }

  updateUsageSum = function () {
    this.loadingData = true;
    // Start date and End date are flipped
    this.usageService.getUsageDataForDay(
      this.end_date,
      this.start_date,
      this.customer,
      'retrieve_daily_usage_sum'
    );
  };

  async ngOnInit(): Promise<void> {
    this.currentUser = this.xgsUmService.xgsActiveUser;

    this.subscription = this.usageService.currentStartDate.subscribe(
      startDate => {
        return (this.start_date = startDate);
      }
    );
    this.subscription = this.usageService.currentEndDate.subscribe(endDate => {
      return (this.end_date = endDate);
    });

    this.subscription = this.usageService.currentDataUsage.subscribe(
      totalDataUsage => {
        return (this.totalDataUsage = totalDataUsage);
      }
    );
    this.subscription = this.usageService.currentDataUsageNumber.subscribe(
      (totalBytes): void => {
        if (totalBytes >= environment.dataUsageLimit) {
          this.dataLevel = 'high';
        } else if (totalBytes >= environment.dataUsageWarning) {
          this.dataLevel = 'medium';
        } else {
          this.dataLevel = 'low';
        }
      }
    );

    this.subscription = this.usageService.currentDailyUsage.subscribe(
      dailyUsageGraph => {
        this.notificationsService.setLoadingState(true);
        const formattedDateTimes = [];
        const formattedData = [];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let x = 0; x < dailyUsageGraph.length; x++) {
          const d: unknown = dailyUsageGraph[x].time as unknown;
          const dt = new Date(d as number);
          formattedDateTimes.push(dt);

          const da: unknown = this.formatGigaBytes(
            dailyUsageGraph[x].data as unknown
          );
          formattedData.push(da as number);
        }
        this.graph.data.splice(0);
        this.graph.data.push({
          x: formattedDateTimes,
          y: formattedData,
          type: 'bar',
          name: 'Daily Data Usage'
        });
        this.validate = true;
        this.notificationsService.setLoadingState(false);
      }
    );

    this.subscription = this.customerService.currentCustomer.subscribe(
      utilityValue => {
        return (this.customer = utilityValue.customer.customerId.toLowerCase());
      }
    );
    this.subscription = this.usageService.currentOnboardedCustomers.subscribe(
      onboardedCustomers => {
        return (this.onboardedCustomers = onboardedCustomers);
      }
    );
    this.subscription = this.usageService.currentCustomerOnboarded.subscribe(
      isCustomerOnboarded => {
        return (this.isCustomerOnboarded = isCustomerOnboarded);
      }
    );

    // This will have the full list when customers are actually onboarded. Need to figure out a better way to filter out false customers
    // users with APPLICATIONADMIN role will see everything, whereas CUSTOMERADMIN
    // will see only the customers they have access to (once this is updated in XGSUM)
    this.xgsUmService.getCustomers().subscribe(async customerData => {
      const custList = customerData.customers;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < custList.length; i++) {
        if (custList[i].name !== 'All Customers') {
          this.customers.push(custList[i]);
        }
      }
    });

    this.tabRoutes = [
      {
        label: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage_sql-queries:SQL Queries`,
          'udl-usage_usage_sql-queries'
        ),
        link: 'usage-tablestorage'
      },
      {
        label: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage_table-storage:Table Storage`,
          'udl-usage_table-storage'
        ),
        link: 'route2'
      }
    ];
  }

  async checkOnboardedStatus(): Promise<boolean> {
    return new Promise(resolve => {
      this.usageService
        .testCustOnboarded('is_customer_onboarded', this.customer)
        .then(isOnboarded => {
          resolve(isOnboarded);
        });
    });
  }

  async updateTables(): Promise<void> {
    this.notificationsService.setLoadingState(true);
    const isOnboarded = await this.checkOnboardedStatus();

    if (isOnboarded) {
      this.usageService.changeEndDate(
        this.startDate.value.utc().format('YYYY-MM-DD')
      );
      this.usageService.changeStartDate(
        this.endDate.value.utc().format('YYYY-MM-DD')
      );
      this.usageService.sendClickEvent();
    } else {
      alert(
        `Customer: ${this.customer} has not been onboarded to XDL. Please have an Admin onboard via the 'Customer Onboarding' screen.`
      );
    }
    this.notificationsService.setLoadingState(false);
  }

  formatGigaBytes(bytes, decimals = 4) {
    if (bytes === 0) {
      return 0;
    }
    const k = 1073741824;
    const dm = decimals < 0 ? 0 : decimals;

    return bytes / k;
  }

  startDateChange(): void {
    this.sDate = this.startDate.value;
  }
}
