<div class="xm-app" style="overflow-y: scroll;">
    <!--Contains The main Page Code excluding the side Filters-->
    <xg-single-content-page [showSidebar]="showSidebar" [hideDivider]="hideDivider">
        <ng-container title>{{cluster_analysis_selected_option_from_popup}}</ng-container>
        <div content class="main-page">
            <div class="row xm-margin-bottom-20" [ngStyle]="{'margin-right' : showSidebar ? '-10rem' : '-1rem'}">
                <div class="col-lg-12">
                    <ng-container content>
                        <div>
                            <mat-button-toggle-group [value]="showSidebar" style="margin-right: 0.5%;">
                                <mat-button-toggle  [value]="true" matTooltip="Open/Close Filtering Options" matTooltipShowDelay="300" (click)="toggleFilter()">
                                    <mat-icon>filter_alt</mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div>
                <app-scatterplot></app-scatterplot>
            </div>
        </div>
        <ng-container sideContent>
            <div class="sidebar-container">
                <div class="xm-view-content-sidebar" style="margin-left: 1%; background: white;">
                    <div class="sidebar" style="background: white;">
                        <div class="sidebar-title" style="background: white;">
                            <span>{{cluster_analysis_selected_option_from_popup}} Filters for {{customer_id.toUpperCase()}}</span>
                            <div class="sidebar-section" style="background: white;">
                                <div class="sidebar-section-label" style="background: white;">
                                    <div class="sidebar-section-content" style="background: white;">
                                        <!--form  [formGroup]="clusteringfilters" (ngSubmit)="onSubmit()"-->
                                        <form  [formGroup]="clusteringfilters">
                                            <mat-error *ngIf="filterError">{{filterErrorMessage}}</mat-error>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Meter Size</mat-label>
                                                <mat-select formControlName="meterSize" [disabled]="meterSizeFilterDisabler">
                                                   <mat-option *ngFor=" let meterSize of meterSizeFilterValues" [value]="meterSize">{{meterSize}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            
                                            <!--mat-form-field appearance="outline">
                                                <mat-label>y-Axis Options</mat-label>
                                                <mat-select formControlName="yAxisOptions" >
                                                    <mat-option *ngFor="let option of yAxisFilterValues" [value]="option">{{option}}</mat-option>
                                                </mat-select>
                                            </mat-form-field-->

                                            <mat-form-field>
                                                <mat-label>y-Axis Options</mat-label>
                                                <input type="text" aria-label="text" matInput formControlName="yAxisOptions" 
                                                [matAutocomplete]="auto">
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option *ngFor="let option of filteredOptions| async" [value]="option">{{option}}</mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            
                                            <mat-form-field appearance="outline">
                                                <mat-label>UOM</mat-label>
                                                <mat-select formControlName="UOM">
                                                    <mat-option *ngFor="let UOM of UOMvalue" [value]="UOM">{{UOM}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline">
                                                <mat-label>Start Date</mat-label>
                                                <input matInput [matDatepicker]="startdatepicker" [max]="maxEndDateAllowed" formControlName="startDate" >
                                                <mat-datepicker-toggle matSuffix [for]="startdatepicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startdatepicker></mat-datepicker>
                                                <!--mat-error field to be used in conjuction with validation logic-->
                                                <!--mat-error *ngIf="!clusteringfilters.get('startDate').errors?.dateLessThan"> Error Message 1</mat-error-->
                                            </mat-form-field>

                                            <mat-form-field appearance="outline">
                                                <mat-label>End Date</mat-label>
                                                <input matInput [matDatepicker]="enddatepicker" [max]="maxEndDateAllowed" formControlName="endDate">
                                                <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
                                                <mat-datepicker #enddatepicker></mat-datepicker>
                                                <!--mat-error field to be used in conjuction with validation logic-->
                                                <!--mat-error *ngIf="clusteringfilters.get('endDate').errors?.dateLessThan"> Error Message 2</mat-error-->
                                            </mat-form-field>

                                            <mat-form-field appearance="outline">
                                                <mat-label>Clustering Constant</mat-label>
                                                <mat-select formControlName="clusteringConstant" >
                                                    <mat-option *ngFor="let no_of_clusters of no_of_clusters_range" [value]="no_of_clusters">{{no_of_clusters}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <!--mat-form-field>
                                                <mat-label>Clustering Constant</mat-label>
                                                <input type="number" placeholder="Select Role" aria-label="Number" matInput formControlName="clusteringConstant" 
                                                [matAutocomplete]="auto">
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option *ngFor="let no_of_clusters of no_of_clusters_range" [value]="no_of_clusters">{{no_of_clusters}}</mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field-->

                                            <button mat-button type="submit" class="filter-btn" [disabled]="applyFilterButtonDisabled" (click)="applyFilters()"> Invoke Clustering </button>
                                            <button mat-button type="button" class="clear-btn" (click)="clearFilterResults()"> Clear Filters </button>
                                        </form>
                                        <br>
                                        <app-old-models></app-old-models>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        
    </xg-single-content-page>
</div>
