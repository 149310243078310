import { NgModule } from '@angular/core';

import { UsageComponent } from './usage.component';
import { UsageTablestorageComponent } from './usage-tablestorage/usage-tablestorage.component';
import { UsageQueryhistoryComponent } from './usage-queryhistory/usage-queryhistory.component';
import { SharedModule } from 'app/shared/shared.module';
import * as PlotlyJS from '../../../node_modules/plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';

// We have to supply the ploty.js module to the angular library
PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    UsageComponent,
    UsageTablestorageComponent,
    UsageQueryhistoryComponent
  ],
  imports: [SharedModule, PlotlyModule]
})
export class UsageModule {}
