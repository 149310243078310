import { NgModule } from '@angular/core';
import {
  mapToCanActivate,
  mapToCanDeactivate,
  mapToCanMatch,
  RouterModule,
  Routes
} from '@angular/router';
import { SupersetComponent } from './superset/superset.component';
import { LandingComponent } from './landing/landing.component';
import { CustomerOnboardComponent } from './customer-onboard/customer-onboard.component';
import { UsageComponent } from './usage/usage.component';
import { AuthGuard, SyncGuardHelper } from './auth/auth-guards/auth.guard';
import { AuthorizeComponent } from './authorize/authorize.component';
import {
  CanDeactivateGuard,
  XgsGuardGuard
} from './auth/auth-guards/xgs-guard.guard';
import { ExdlPermissions } from '@common/models/user-management.model';

const routes: Routes = [
  {
    path: 'landing',
    component: LandingComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([XgsGuardGuard])
  },
  { path: '', redirectTo: '/landing', pathMatch: 'full' },
  { path: 'home', redirectTo: '/landing', pathMatch: 'full' },
  { path: 'authorize', component: AuthorizeComponent, pathMatch: 'full' },
  {
    path: 'superset',
    component: SupersetComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([SyncGuardHelper]),
    data: {
      syncGuards: [XgsGuardGuard, AuthGuard],
      permission: 'Superset'
    }
  },
  {
    path: 'customer-onboard',
    component: CustomerOnboardComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([SyncGuardHelper]),
    data: {
      syncGuards: [XgsGuardGuard, AuthGuard],
      permission: ExdlPermissions.CUSTOMER_ONBOARDING
    }
  },
  {
    path: 'usage-screen',
    component: UsageComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([SyncGuardHelper]),
    data: {
      syncGuards: [XgsGuardGuard, AuthGuard],
      permission: ExdlPermissions.RESOURCE_USAGE
    }
  },
  {
    path: 'user',
    canMatch: mapToCanMatch([XgsGuardGuard]),
    canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
    loadChildren: async () => {
      const m = await import('./user-management/user/user.module');

      return m.UserModule;
    }
  },
  {
    path: 'role',
    canMatch: mapToCanMatch([XgsGuardGuard]),
    canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
    loadChildren: async () => {
      const m = await import('./user-management/role/role.module');

      return m.RoleModule;
    }
  },
  {
    path: 'scheduler',
    canMatch: mapToCanMatch([XgsGuardGuard]),
    canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
    loadChildren: async () => {
      const m = await import('./scheduler/scheduler.module');

      return m.SchedulerModule;
    }
  },
  {
    path: '**',
    redirectTo: 'landing'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
