import { CustomTranslatorService } from 'app/shared/services/custom-translator/custom-translator.service';
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AmplifyService } from 'app/aws/amplify/amplify.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { SummaryCount } from '@gravity-angular/components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsagePreference } from '@common/models/user-preference.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LoadingService } from '@gravity-angular/layout';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

export interface StorageElement {
  name: string;
  storage: number;
  rows: number;
}

export interface QueryElement {
  rows: number;
  data: number;
  query: string;
  user: string;
  start: string;
  end: string;
  duration: string;
}

export interface UsageGraphDataElement {
  time: string;
  data: number;
}

const STORAGE_ELEMENT_DATA: StorageElement[] = [];
const QUERY_ELEMENT_DATA: QueryElement[] = [];
const USAGE_ELEMENT_DATA: UsageGraphDataElement[] = [];

@Injectable({
  providedIn: 'root'
})
export class UsageService {
  usagePreference = {} as UsagePreference;
  usagePageSize = new BehaviorSubject(null);
  queryUsageSum: number;
  tableUsageSum: number;

  storageDataSource = new MatTableDataSource<StorageElement>(
    STORAGE_ELEMENT_DATA
  );

  queryDataSource = QUERY_ELEMENT_DATA;
  usageDataSource = new MatTableDataSource<UsageGraphDataElement>(
    USAGE_ELEMENT_DATA
  );

  storageSummaryCounts: SummaryCount[];
  querySummaryCounts: SummaryCount[];
  usageSummaryCounts: SummaryCount[];

  storageDataLoaded = false;
  queryDataLoaded = false;

  private readonly _snackBar: MatSnackBar;

  constructor(
    private readonly amplifyService: AmplifyService,
    private readonly loadingService: LoadingService,
    private readonly datadogService: DatadogService,
    public customTranslatorService: CustomTranslatorService
  ) {}

  // TOTAL STORAGE USAGE

  // SNACKBAR
  private readonly snackbarSource = new Subject<MatSnackBar>();
  currentSnackbar = this.snackbarSource.asObservable();
  changeSnackbar(snackbar: MatSnackBar) {
    this.snackbarSource.next(snackbar);
  }

  // SELECTED CUSTOMER
  private readonly selectedCustomerSource = new BehaviorSubject('');
  currentCustomer = this.selectedCustomerSource.asObservable();
  changeSelectedCustomer(customer: string) {
    this.selectedCustomerSource.next(customer);
  }

  // TABLE STORAGE
  private readonly storageSourceTest = new Subject<
    MatTableDataSource<StorageElement>
  >();

  currentStorage = this.storageSourceTest.asObservable();
  changeStorage(storage: MatTableDataSource<StorageElement>) {
    this.storageSourceTest.next(storage);
  }

  // TABLE STORAGE SUMMARY
  private readonly storageSummarySource = new Subject<SummaryCount[]>();
  currentStorageSummary = this.storageSummarySource.asObservable();
  changeStorageSummary(storageSummary: SummaryCount[]) {
    this.storageSummarySource.next(storageSummary);
  }

  // QUERY HISTORY
  private readonly queryHistorySource = new Subject<QueryElement[]>();

  currentQueryHistory = this.queryHistorySource.asObservable();
  changeQueryHistory(queryHistory: QueryElement[]) {
    this.queryHistorySource.next(queryHistory);
  }

  // QUERY HISTORY SUMMARY
  private readonly querySummarySource = new Subject<SummaryCount[]>();
  currentQuerySummary = this.querySummarySource.asObservable();
  changeQuerySummary(querySummary: SummaryCount[]) {
    this.querySummarySource.next(querySummary);
  }

  // DATA USAGE BY DAY
  private readonly dailyUsage = new Subject<UsageGraphDataElement[]>();
  currentDailyUsage = this.dailyUsage.asObservable();
  changeDailyDataUsage(usageGraphData: UsageGraphDataElement[]) {
    this.dailyUsage.next(usageGraphData);
  }

  // DATA USAGE SUMMARY
  private readonly dailySummarySource = new Subject<SummaryCount[]>();
  currentDailySummary = this.dailySummarySource.asObservable();
  changeDailySummary(dailySummary: SummaryCount[]) {
    this.dailySummarySource.next(dailySummary);
  }

  // DATA USAGE FOR MONTH
  private readonly dataUsage = new BehaviorSubject('');
  private readonly dataUsageNum = new BehaviorSubject<number>(0);
  currentDataUsage = this.dataUsage.asObservable();
  currentDataUsageNumber = this.dataUsageNum.asObservable();
  changeDataUsage(queryData: string, queryDataNumber: number) {
    this.dataUsage.next(queryData);
    this.dataUsageNum.next(queryDataNumber);
  }

  // ONBOARDED CUSTOMERS
  private readonly onboardedCustomerSource = new BehaviorSubject({});
  currentOnboardedCustomers = this.onboardedCustomerSource.asObservable();
  changeOnboardedCustomers(onboardedCustomers: object) {
    this.onboardedCustomerSource.next(onboardedCustomers);
  }

  // IS CURRENT CUSTOMER ONBOARDED
  private readonly currentCustomerOnboardedSource = new BehaviorSubject(false);
  currentCustomerOnboarded = this.currentCustomerOnboardedSource.asObservable();
  changeCurrentCustomerOnboarded(isCustomerOnboarded: boolean) {
    this.currentCustomerOnboardedSource.next(isCustomerOnboarded);
  }

  // SUBMIT BUTTON
  private readonly subject = new Subject<any>();
  sendClickEvent() {
    this.subject.next('ClickEvent');
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  // START DATE
  private readonly startDateSource = new BehaviorSubject('5/01/2022');
  currentStartDate = this.startDateSource.asObservable();
  changeStartDate(startDate: string) {
    this.startDateSource.next(startDate);
  }

  // END DATE
  private readonly endDateSource = new BehaviorSubject('5/01/2022');
  currentEndDate = this.endDateSource.asObservable();
  changeEndDate(endDate: string) {
    this.endDateSource.next(endDate);
  }

  setTableCategory(table_name: string) {
    if (table_name.includes('events')) {
      return 'events';
    } else if (table_name.includes('intervals')) {
      return 'intervals';
    } else if (table_name.includes('registers')) {
      return 'registers';
    } else if (table_name.includes('device')) {
      return 'device';
    } else {
      return 'other';
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  formatBytesSummaryCount(bytes, decimals = 2) {
    if (bytes === 0) {
      return 0;
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }

  async getTableStorage(customer_id, method, start_date, end_date) {
    this.loadingService.showLoading(true);
    const dataSource = [];
    const config = {
      queryParams: {
        customer_id,
        method,
        // TODO: figure out why these are swapped
        start_date,
        end_date
      }
    };
    try {
      const response = await this.amplifyService.callAPI(
        'GET',
        'GUI_UDL_Usage',
        '/get-data',
        config,
        false
      );
      if (response['error'] === 'true' || response['error'] === true) {
        this.datadogService.errorTracking(new Error(JSON.stringify(response)), {
          message: 'Error occurred while fetching table storage data'
        });
      } else {
        const data = response;

        this.getredshift_DataFromCSV(customer_id, data);
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching table storage data'
      });
    }
    this.loadingService.showLoading(false);
  }

  async retrieveUsageSum(
    startDate,
    endDate,
    customerId,
    method
  ): Promise<number> {
    this.loadingService.showLoading(true);
    try {
      const config = {
        queryParams: {
          method,
          customer_id: customerId,
          start_date: startDate,
          end_date: endDate
        }
      };
      const response = await this.amplifyService.callAPI(
        'GET',
        'GUI_UDL_Usage',
        '/get-data',
        config,
        false
      );
      if (response['error']) {
        throw new Error(response['error']);
      } else {
        const data = JSON.parse(response as string);
        const scanData = data.data[0][0];

        if (scanData.longValue) {
          this.changeDataUsage(
            this.formatBytes(scanData.longValue),
            scanData.longValue
          );
        } else {
          this.changeDataUsage(this.formatBytes(0), 0);
        }
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching usage sum'
      });
    }
    this.loadingService.showLoading(false);

    return this.dataUsageNum.value;
  }

  async getQueries(customer_id, method, start_date, end_date) {
    this.loadingService.showLoading(true);
    const config = {
      queryParams: {
        customer_id,
        start_date, // '2022-04-19',
        end_date, // '2022-05-27',
        method
      }
    };
    try {
      const response = await this.amplifyService.callAPI(
        'GET',
        'GUI_UDL_Usage',
        '/get-data',
        config,
        false
      );
      if (response['error'] === 'true' || response['error'] === true) {
        this.datadogService.errorTracking(new Error(JSON.stringify(response)), {
          message: 'Error occurred while fetching usage queries'
        });
      } else {
        const data = response;
        console.log('Query data: ', data);

        this.getredshift_QueryDataFromCSV(
          customer_id,
          data,
          start_date,
          end_date
        );
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching usage queries'
      });
    }
    this.loadingService.showLoading(false);
  }

  async getOnboardedCustomers(method, customer_id) {
    this.loadingService.showLoading(true);
    const config = {
      queryParams: {
        method,
        customer_id
      }
    };
    try {
      const response = await this.amplifyService.callAPI(
        'GET',
        'GUI_UDL_Usage',
        '/get-data',
        config,
        false
      );
      if (response['error'] === 'true' || response['error'] === true) {
        this.datadogService.errorTracking(new Error(JSON.stringify(response)), {
          message: 'Error occurred while fetching onboarded customers'
        });
      } else {
        const data = JSON.parse(response as string);
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching onboarded customers'
      });
    }
    this.loadingService.showLoading(false);
  }

  async isCustomerOnboarded(method, customer_id) {
    this.loadingService.showLoading(true);
    const config = {
      queryParams: {
        method,
        customer_id
      }
    };
    try {
      const response = await this.amplifyService.callAPI(
        'GET',
        'GUI_UDL_Usage',
        '/get-data',
        config,
        false
      );
      if (response['error'] === 'true' || response['error'] === true) {
        this.datadogService.errorTracking(new Error(JSON.stringify(response)), {
          message: 'Error occurred verifiying customer onboarded status'
        });
      } else {
        if (JSON.parse(JSON.stringify(response)) === 'YES') {
          this.changeCurrentCustomerOnboarded(true);

          return true;
        } else {
          this.changeCurrentCustomerOnboarded(false);

          return false;
        }
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred verifiying customer onboarded status'
      });
    }
    this.loadingService.showLoading(false);
  }

  async getUsageDataForDay(start_date, end_date, customer_id, method) {
    this.loadingService.showLoading(true);
    let dataSource = [];
    const config = {
      queryParams: {
        method,
        customer_id,
        start_date,
        end_date
      }
    };
    try {
      const result = await this.amplifyService.callAPI(
        'GET',
        'GUI_UDL_Usage',
        '/get-data',
        config,
        false
      );
      if (result['error'] == 'true' || result['error'] == true) {
        console.log('getUsageDataForDay - Error');
      } else {
        const data = JSON.parse(result as string);

        // FOR DATA USAGE HISTORY (EACH DAY)
        const data_history = [];
        let summary_data_day = 0;
        let summary_data = 0;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let x = 0; x < data.data.length; x++) {
          const time = data.data[x][0];
          const scan_data = data.data[x][1];

          summary_data_day += 1;
          summary_data += scan_data.longValue;

          data_history.push({
            time: time.stringValue,
            data: scan_data.longValue
          });
        }

        this.usageDataSource.data = data_history;

        this.usageSummaryCounts = [
          {
            label: 'DATA By Day',
            count: summary_data_day
          },
          {
            label: `Data Scanned (${this.formatBytes(summary_data).split(
              ' '
            )})`,
            count: this.formatBytesSummaryCount(summary_data)
          }
        ];

        dataSource = data_history;

        this.changeDailyDataUsage(dataSource);
        this.changeDailySummary(this.usageSummaryCounts);
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred fetching usage data by date'
      });
    }
    this.loadingService.showLoading(false);
  }

  async testCustOnboarded(method, customer_id): Promise<boolean> {
    let onboarded = false;
    const config = {
      queryParams: {
        method,
        customer_id
      }
    };

    return new Promise(async resolve => {
      try {
        const result = await this.amplifyService.callAPI(
          'GET',
          'GUI_UDL_Usage',
          '/get-data',
          config,
          false
        );
        if (result['error'] === 'true' || result['error'] === true) {
          this.datadogService.errorTracking(new Error(JSON.stringify(result)), {
            message: 'Error occurred testing customer onboarded status'
          });
        } else {
          onboarded =
            JSON.parse(JSON.stringify(result)) === 'YES' ? true : false;
          resolve(onboarded);
        }
      } catch (error) {
        this.datadogService.errorTracking(error, {
          message: 'Error occurred testing customer onboarded status'
        });
      }
    });
  }

  async getredshift_DataFromCSV(customer_id, lambda_id) {
    let dataSource = [];
    const key2 = `from_redshift/gui/usage_screen/sys_table_summary_table_storage/${customer_id}/${lambda_id}/000`;

    const s3Data = await this.amplifyService.getFileFromS3(key2);
    let blob = [] as any;
    blob = s3Data.body;

    const text = await new Response(blob).text();

    const data = text.split('\n');

    const todays_storage = [
      {
        name: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_events:Events`,
          'udl-usage_usage-service_events'
        ),
        storage: 0,
        rows: 0,
        label: 'Events'
      },
      {
        name: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_device:Device`,
          'udl-usage_usage-service_device'
        ),
        storage: 0,
        rows: 0,
        label: 'Device'
      },
      {
        name: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_registers:Registers`,
          'udl-usage_usage-service_registers'
        ),
        storage: 0,
        rows: 0,
        label: 'Registers'
      },
      {
        name: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_intervals:Intervals`,
          'udl-usage_usage-service_intervals'
        ),
        storage: 0,
        rows: 0,
        label: 'Intervals'
      },
      {
        name: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_other:Other`,
          'udl-usage_usage-service_other'
        ),
        storage: 0,
        rows: 0,
        label: 'Other'
      }
    ];
    let most_recent_datetime = '0-00-0000';
    let most_recent_date = '00:00:00:00';
    const summary_tables = 0;
    let summary_storage = 0;
    let summary_rows = 0;

    if (data[0] != '') {
      const most_recent = data[0].split('|');
      most_recent_datetime = most_recent[3];
      most_recent_date = most_recent_datetime.substring(
        0,
        most_recent_datetime.indexOf(' ')
      );

      for (let x = 0; x < data.length - 1; x++) {
        const arr = data[x].split('|');
        const query_datetime = arr[3].split(' ');
        const query_date = query_datetime[0];
        const table_name = arr[0];
        const total_storage = arr[1];
        const total_rows = arr[2];
        if (query_date == most_recent_date) {
          console.log('INSIDE QUERY DATE FUNCTION');
          summary_storage += Number(total_storage);
          summary_rows += Number(total_rows);
          const objIndex = todays_storage.findIndex(obj => {
            return obj.label.toLowerCase() == this.setTableCategory(table_name);
          });
          todays_storage[objIndex].storage += Number(total_storage);
          todays_storage[objIndex].rows += Number(total_rows);
        }
      }
    }

    this.storageDataSource.data = todays_storage;

    this.storageSummaryCounts = [
      {
        label: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_tables:Tables`,
          'udl-usage_usage-service_tables'
        ),
        count: todays_storage.length - 1 // summary_tables
      },
      {
        // data is stored in MB from the ETL run due to the output of the system view for table storage
        label: `${this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_storage:Storage`,
          'udl-usage_usage-service_storage'
        )} (MB)`,
        count: summary_storage
      },
      {
        label: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_rows:Rows`,
          'udl-usage_usage-service_rows'
        ),
        count: summary_rows
      }
    ];

    dataSource = todays_storage;
    this.changeStorageSummary(this.storageSummaryCounts);
    this.changeStorage(this.storageDataSource);
  }

  async getredshift_QueryDataFromCSV(customerId, lambdaId, startDate, endDate) {
    const s3Key = `from_redshift/gui/usage_screen/sys_table_summary_query_history/${customerId}/${lambdaId}/000`;
    const s3Data = await this.amplifyService.getFileFromS3(s3Key);
    const blob = s3Data.body;

    const text = await new Response(blob).text();
    const data = text.split('\n');

    const queryHistory = [];
    let summaryQueries = 0;
    let summaryData = 0;
    let summaryRows = 0;
    for (const row of data) {
      if (!row) {
        continue;
      }
      const arr = row.split('\t');
      const userId = arr[0];
      const scanRowCount = Number(arr[2]);
      const scanData = Number(arr[3]);
      const sqlQuery = arr[4];
      const startTime = arr[5];
      const cStartTime = Date.parse(startTime.split(' ', 1)[0]);
      const endTime = arr[6];
      const cEndTime = Date.parse(endTime.split(' ', 1)[0]);
      const queryExecutionTime = Date.parse(endTime) - Date.parse(startTime);

      // DATE ARES FLIPPED -----
      if (
        cStartTime >= Date.parse(endDate) &&
        cEndTime <= Date.parse(startDate)
      ) {
        summaryQueries += 1;
        summaryData += scanData;
        summaryRows += scanRowCount;
        queryHistory.push({
          rows: scanRowCount,
          data: scanData,
          query: sqlQuery,
          user: userId,
          start: startTime,
          end: endTime,
          duration: queryExecutionTime
        });
      }
    }

    this.queryDataSource = queryHistory;
    this.querySummaryCounts = [
      {
        label: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_queries:Queries`,
          'udl-usage_usage-service_queries'
        ),
        count: summaryQueries
      },
      {
        label: `${this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_dataScan:Data Scanned`,
          'udl-usage_usage-service_dataScan'
        )} (${this.formatBytes(summaryData).split(' ').pop()})`,
        count: this.formatBytesSummaryCount(summaryData)
      },
      {
        label: this.customTranslatorService.translate(
          $localize`:@@udl-usage_usage-service_rowScan:Rows Scanned`,
          'udl-usage_usage-service_rowScan'
        ),
        count: summaryRows
      }
    ];

    this.changeQueryHistory(this.queryDataSource);
    this.changeQuerySummary(this.querySummaryCounts);
  }

  /**
   * Updates usage preference in locale storage
   * @param key preference key
   * @param value preference value
   */
  updateUsagePreferences(key: string, value: any): void {
    this.usagePreference[key] = value;
    localStorage.setItem('usage', JSON.stringify(this.usagePreference));
  }

  /**
   * Retrieves usage preferences from locale storage
   * @returns usage preferences from locale storage
   */
  getPreferences(): UsagePreference {
    const preference = localStorage.getItem('usage');
    if (preference) {
      return JSON.parse(preference);
    }
  }

  /**
   * Saves value used for page size of
   * usage list to persist across session to be subscribed to
   * @param event pagination event captured in mat-table
   */
  updatedUsagePageSize(event: PageEvent) {
    this.usagePageSize.next(event);
  }

  /**
   * Helper function to set page size and page index of mat-tables via their mat-paginator
   * @param paginators list of MatPaginator to set page size
   * @param size size to set page to of mat-table
   * @param pageEvent mat-paginator event
   * @param path either user or role path
   */
  setScreenPaginators(
    paginators: MatPaginator[],
    pageEvent: PageEvent,
    path: string = null
  ): void {
    for (const paginator of paginators) {
      const preferences = this.getPreferences();
      if (paginator && paginator.pageSize !== pageEvent.pageSize) {
        paginator.pageSize = pageEvent.pageSize;
        paginator.page.emit({
          length: paginator.length,
          pageIndex: paginator.pageIndex,
          pageSize: paginator.pageSize
        });
      }
    }
  }
}
