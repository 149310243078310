/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AmplifyService } from '../../../aws/amplify/amplify.service';
import {
  ExdlSupersetRoles,
  UserAuditItem,
  UserAuditRequest,
  XGSRole
} from '@common/models/user-management.model';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

/**
 * Service for managing user audit trails, including creating audit entries for role assignments and revocations.
 */
@Injectable({
  providedIn: 'root'
})
export class UserAuditService {
  constructor(
    private readonly amplifyService: AmplifyService,
    private readonly datadogService: DatadogService
  ) {}

  /**
   * Creates an audit trail request for assigning roles and permissions to a user.
   *
   * @param params - The audit item parameters.
   * @param tempAudit - The temporary audit data containing role and permission assignments.
   * @param initial_value - The initial values before the role assignment.
   * @param success - The success status of the assignment.
   * @returns A promise that resolves when the audit trail entry is added.
   */
  async assignRoleAudit(
    params: UserAuditItem,
    tempAudit: Object,
    initial_value: Object,
    success: string
  ): Promise<void> {
    const payload = {
      auditItems: [] as UserAuditItem[]
    };
    for (const customerID of Object.keys(tempAudit)) {
      params.success = success;
      params.customer_id = [customerID];
      if (tempAudit[customerID]['BiAccess']) {
        const item = this.genericAssignRoleHelper(
          structuredClone(params),
          customerID,
          'BiAccess',
          'Granted External BI Tool Permission/Access'
        );
        payload.auditItems.push(item);
      }
      if (tempAudit[customerID][ExdlSupersetRoles.CUSTOMER_ACCESS]) {
        const item = this.genericAssignRoleHelper(
          structuredClone(params),
          customerID,
          ExdlSupersetRoles.CUSTOMER_ACCESS,
          'Granted Superset Customer Access'
        );
        payload.auditItems.push(item);
      }
      if (
        tempAudit[customerID]['supersetPermissions'] &&
        tempAudit[customerID]['supersetPermissions'].length > 0
      ) {
        const item = this.assignSupersetPermissions(
          structuredClone(params),
          customerID,
          tempAudit,
          initial_value
        );
        payload.auditItems.push(item);
      }
      if (tempAudit[customerID]['role']) {
        const item = this.assignRoleHelper(
          structuredClone(params),
          customerID,
          tempAudit
        );
        payload.auditItems.push(item);
      }
    }
    await this.addUserTrail(payload);
  }

  /**
   * Builds payload for user audit trail for standard roles
   * @param params user audit payload template
   * @param customerID customer to associate history too
   * @param tempAudit Object holding customers and history to associate
   * @returns user audit payload
   */
  assignRoleHelper(
    params: UserAuditItem,
    customerID: string,
    tempAudit: Object
  ): UserAuditItem {
    params.initial_value = JSON.stringify({});
    const altered_value = { roles: {} };
    altered_value['roles'][tempAudit[customerID]['role']] = [customerID];
    params.altered_value = JSON.stringify(altered_value);
    params.user_action = 'Role Assigned to User';

    return params;
  }

  /**
   * Builds payload for user audit trail for superset permissions
   * @param params user audit payload template
   * @param customerID customer to associate history too
   * @param tempAudit Object holding customers and history to associate
   * @param initial_value initial user value before change
   * @returns user audit payload
   */
  assignSupersetPermissions(
    params: UserAuditItem,
    customerID: string,
    tempAudit: Object,
    initial_value: Object
  ): UserAuditItem {
    const initialSuperset = { ...initial_value };
    for (const key in initialSuperset['roles']) {
      if (
        !key.includes('Superset') &&
        key !== ExdlSupersetRoles.CUSTOMER_ACCESS
      ) {
        delete initialSuperset['roles'][key];
      } else if (!initialSuperset['roles'][key].includes(customerID)) {
        delete initialSuperset['roles'][key];
      }
    }
    params.initial_value = JSON.stringify(initialSuperset);
    const altered_value = { ...initialSuperset };
    for (const permission of tempAudit[customerID]['supersetPermissions']) {
      altered_value['roles'][permission] = [customerID];
    }
    params.altered_value = JSON.stringify(altered_value);
    params.user_action = 'Granted Superset Permission';

    return params;
  }

  /**
   * Builds user audit payload
   * @param params user audit payload template
   * @param customerID customer to associate history too
   * @param roleName name of role for history
   * @param action action before performed
   * @returns user audit payload
   */
  genericAssignRoleHelper(
    params: UserAuditItem,
    customerID: string,
    roleName: string,
    action: string
  ): UserAuditItem {
    params.initial_value = JSON.stringify({});
    params.altered_value = JSON.stringify({
      roles: {
        [`${roleName}`]: [customerID]
      }
    });
    params.user_action = action;

    return params;
  }

  /**
   * Creates an audit trail request for revoking a role from a user.
   *
   * @param role - The role being revoked.
   * @param params - The audit item parameters.
   * @param customers - The list of customers associated with the role.
   * @param user - The user whose role is being revoked.
   * @param success - The success status of the revocation.
   * @returns The audit trail request payload.
   */
  revokeRoleAudit(
    role: XGSRole,
    params: UserAuditItem,
    customers: string[],
    user: object,
    success: string
  ): UserAuditRequest {
    const payload: UserAuditRequest = { auditItems: [] };
    params.success = success;
    if (role.name === ExdlSupersetRoles.CUSTOMER_ACCESS) {
      params.initial_value = JSON.stringify({
        roles: {
          SupersetCustomerAccess: customers
        }
      });
      params.altered_value = JSON.stringify({});
      params.user_action = 'Revoked Superset Customer Access';
      payload.auditItems.push(params);

      return payload;
    } else if (role.name === 'BiAccess') {
      params.initial_value = JSON.stringify({
        roles: {
          BiAccess: customers
        }
      });
      params.altered_value = JSON.stringify({});
      params.user_action = 'Revoked External BI Access';

      payload.auditItems.push(params);

      return payload;
    } else {
      const initial_value = { roles: {} };
      for (const standRole in user['roles'][environment.xgsClientID]) {
        if (
          !standRole.includes('Superset') &&
          !standRole.includes('OXI:') &&
          standRole !== 'BiAccess'
        ) {
          for (const customer of customers) {
            if (
              user['roles'][environment.xgsClientID][standRole].includes(
                customer
              )
            ) {
              if (initial_value['roles'][standRole] === undefined) {
                initial_value['roles'][standRole] = [];
              }
              initial_value['roles'][standRole].push(customer);
            }
          }
        }
      }
      const altered_value = JSON.parse(JSON.stringify(initial_value));
      delete altered_value['roles'][role.name];
      params.initial_value = JSON.stringify(initial_value);
      params.altered_value = JSON.stringify(altered_value);
      params.user_action = 'Revoked Role';

      payload.auditItems.push(params);

      return payload;
    }
  }

  /**
   * Builds Audit Payload for Revoked Superset Roles
   * @param roles List of Superset Roles being revoked
   * @param params initial params to send
   * @param customer customer roles are being revoked from
   * @param user user having roles removed from
   * @param success Yes of No
   * @returns Audit payload
   */
  revokeSupersetRolesAudit(
    roles: XGSRole[],
    params: Object,
    customer: string,
    user: object,
    success: string
  ): Object {
    params['success'] = success;
    const initial_value = { roles: {} };
    for (const superRole in user['roles'][environment.xgsClientID]) {
      if (
        superRole.includes('Superset') &&
        superRole !== ExdlSupersetRoles.CUSTOMER_ACCESS
      ) {
        if (
          user['roles'][environment.xgsClientID][superRole].includes(customer)
        ) {
          if (!initial_value['roles'][superRole]) {
            initial_value['roles'][superRole] = [];
          }
          initial_value['roles'][superRole].push(customer);
        }
      }
    }
    const altered_value = JSON.parse(JSON.stringify(initial_value));
    for (const role of roles) {
      delete altered_value['roles'][role.name];
    }
    params['initial_value'] = JSON.stringify(initial_value);
    params['altered_value'] = JSON.stringify(altered_value);
    params['user_action'] = 'Revoked Superset Permission';

    return params;
  }

  /**
   * Makes an API call to add an entry to the user audit trail.
   *
   * @param body - The request body containing the audit items to be added.
   * @returns A promise that resolves to the API response or undefined if an error occurs.
   */
  async addUserTrail(body: UserAuditRequest): Promise<Object> {
    if (!body?.auditItems?.length) {
      return;
    }
    const request = { body };
    try {
      const apiResponse = await this.amplifyService.callAPI(
        'post',
        'API_UDL_UM_AUDIT_TRAIL',
        '/add-user-trail',
        request
      );

      return apiResponse;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred updating user history'
      });

      return;
    }
  }

  /**
   * Makes an API call to get user audit trail entries.
   *
   * @param body - The request body containing the parameters for the API call.
   * @returns A promise that resolves to the user audit trail entries.
   */
  async getUserTrail(body: Object): Promise<Object> {
    const request = { body };

    return this.amplifyService.callAPI(
      'post',
      'API_UDL_UM_AUDIT_TRAIL',
      '/get-user-trail',
      request
    );
  }

  /**
   * Gets epoch time of alteration timestamp for sorting
   * @param item UDL user audit trail object
   * @returns epoch time of alteration timestamp
   */
  auditTimeSortFunction(item: Object): number {
    return item['alteration_timestamp']
      ? new Date(item['alteration_timestamp'].replace(/['"]+/g, '')).getTime()
      : null;
  }
}
