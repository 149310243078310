import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blank-homepage',
  templateUrl: './blank-homepage.component.html',
  styleUrls: ['./blank-homepage.component.scss']
})
export class BlankHomepageComponent implements OnInit {
  // Remove this code while refactoring
  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '40%',
      height: '50%',
      maxHeight: '70%',
      maxWidth: '60%',
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(value_from_popup => {
      this.router.navigate(['ClusterAnalysis/clusterAnalysisHomePage']);
    });
  }
}
