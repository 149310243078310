<div class="container">
  <div class="carousel">
    <ul class="list-ul">
      <li>
        <div class="carousel-slide" [@carouselAnimations] *ngIf="0 === currentIndex">
          <div class="carousel-text">
            <h1 class="overview" i18n="@@landing_carousel-slider_slide_1_header" [textContent]="'landing_carousel-slider_slide_1_header' | translate">The Power of Data</h1>
              <p class="description" i18n="@@landing_carousel-slider_slide_1_description" [textContent]="'landing_carousel-slider_slide_1_description' | translate">Xylem Data Lake exist to help YOU leverage the power of your big data to provide value to your enterprise</p>
          </div>
          <div class="carousel-right">
            <img class="carousel-image" [lazyLoad]="'assets/hero.png'" [src]="'assets/hero.png'" loading="lazy">
          </div>
        </div>
      </li>
      <li>
        <div class="carousel-slide" [@carouselAnimations] *ngIf="1 === currentIndex">
          <div class="carousel-text">
            <h1 class="overview" i18n="@@landing_carousel-slider_slide_2_header" [textContent]="'landing_carousel-slider_slide_2_header' | translate">Need Additional Help to determine what is actionable with your data?</h1>
              <p class="description" i18n="@@landing_carousel-slider_slide_2_description" [textContent]="'landing_carousel-slider_slide_2_description' | translate">Xylem offers professional business process consulting, software development & data science services that can be tailored to your specific needs through the Xylem Data Lake.</p>
              <div class="link-button">
                <a class="carousel-link" (click)="learnMore('https://sensus.com/services/')" i18n="@@landing_carousel-slider_slide_2_learn" [textContent]="'landing_carousel-slider_slide_2_learn' | translate">Learn More</a>
              </div>
          </div>
          <div class="carousel-right">
            <img class="carousel-image" [lazyLoad]="'assets/xylem_services.jpg'" [src]="'assets/xylem_services.jpg'" loading="lazy">
          </div>
        </div>
      </li>
      <li>
        <div class="carousel-slide" [@carouselAnimations] *ngIf="2 === currentIndex">
          <div class="carousel-text">
            <h1 class="overview" i18n="@@landing_carousel-slider_slide_3_header" [textContent]="'landing_carousel-slider_slide_3_header' | translate">Pressure Profile</h1>
              <p class="description" i18n="@@landing_carousel-slider_slide_3_description" [textContent]="'landing_carousel-slider_slide_3_description' | translate">Ensure Safe, Reliable Water Service throughout your Distribution System. Learn about the latest offer in the Sensus Analytics portfolio of products</p>
              <div class="link-button">
                <a class="carousel-link" (click)="learnMore('https://sensus.com/products/pressure-profile')" i18n="@@landing_carousel-slider_slide_3_learn" [textContent]="'landing_carousel-slider_slide_3_learn' | translate">Learn More</a>
              </div>
          </div>
          <div class="carousel-right">
            <img class="carousel-image" [lazyLoad]="'assets/Pressure_Profile_Screen.png'" [src]="'assets/Pressure_Profile_Screen.png'" loading="lazy">
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="button-container">
    <div class="carousel-buttons">
      <button class="carousel-button"
        [ngStyle]="{'opacity': currentIndex===0? 1.0 : 0.5}"
        (click)= "changeImgIndex(0)">
      </button>
      <button class="carousel-button"
        [ngStyle]="{'opacity': currentIndex===1? 1.0 : 0.5}"
        (click)= "changeImgIndex(1)">
      </button>
      <button class="carousel-button"
        [ngStyle]="{'opacity': currentIndex===2? 1.0 : 0.5}"
        (click)= "changeImgIndex(2)">
      </button>
    </div>
  </div>
</div>
